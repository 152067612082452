.button {
	--font-size-button: var(--font-size-m);
	--font-family-button: var(--font-family-regular);
	--button-height: 4rem;
	--button-transition: 0.25s var(--ease-in-out-quad);
	--hover-color: var(--color-black);
	--box-shadow: var(--box-shadow-bottom--4-steps);
	--button-text-color: var(--color-gold);
	--button-background-color: var(--color-blue);
	--button-spacing: var(--spacing);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.125rem;
	font-size: var(--font-size-button);
	height: 4rem;
	height: var(--button-height);
	color: rgb(180, 150, 83);
	color: var(--button-text-color);
	background-color: rgb(27, 45, 57);
	background-color: var(--button-background-color);
	border: none;
	cursor: pointer;
	border-radius: 999rem;
	border-radius: var(--border-radius--round);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1);
	box-shadow: var(--box-shadow);
	padding: 0.3em calc(1.5rem * 2) 0;
	padding: 0.3em calc(var(--button-spacing) * 2) 0;
	outline: none;
	margin-top: 1.5rem;
	margin-top: var(--button-spacing);
	margin-bottom: 1.5rem;
	margin-bottom: var(--button-spacing);
	transition: 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: var(--button-transition);
	transition-property: box-shadow, transform, opacity;
	position: relative;
	z-index: 0;
	-webkit-text-decoration: none;
	text-decoration: none;
}

	.button[disabled] {
		pointer-events: none;
		opacity: 0.3;
	}

	[data-ui-background-color='dark'] .button {
		--button-primary-color: var(--color-white);
	}

	[data-ui-background-color='light'] .button {
		--button-primary-color: var(--color-blue);
	}

	.button:not(.button--text):not(.button--link):hover {
			--box-shadow: var(--box-shadow-bottom--5-steps);
			transform: scale(1.025);
		}

	.button:not(.button--text):not(.button--link):active {
			transform: scale(1);
			--box-shadow: var(--box-shadow-bottom--3-steps);
			transition-duration: 0.08s;
		}

	.button > span {
		position: relative;
		z-index: 10;
		pointer-events: none;
		font-family: var(--font-family-button);
	}

	.button--primary {
		--button-background-color: var(--color-teal);
		--button-text-color: var(--color-blue);
		--loading-spinner-color-1: var(--color-blue);
		--loading-spinner-color-2: var(--color-blue);
	}

	.modal--success .button--primary {
			--button-background-color: var(--color-white);
			--button-text-color: var(--color-blue);
		}

	.button--secondary {
		--button-background-color: var(--color-white);
		--button-text-color: var(--color-blue);
		--loading-spinner-color-1: var(--color-blue);
		--loading-spinner-color-2: var(--color-blue);
	}

	.modal .button--secondary {
			--button-background-color: var(--color-black);
			--button-text-color: var(--color-white);
		}

	.button--round {
		--button-height: 3rem;
		width: 3rem;
		width: var(--button-height);
		--button-spacing: var(--spacing--small);
		--font-size-button: var(--font-size-s);
		margin: 0;
		padding-top: 0;
	}

	.button--round span {
			display: contents;
		}

	.button--round svg {
			height: 100%;
		}

	.button--text {
		--button-background-color: transparent;
		--font-family-button: var(--font-family-regular);
		--button-spacing: 0;
		--box-shadow: none;
	}

	.button--link {
		--button-background-color: transparent;
		--font-family-button: inherit;
		--font-size-button: var(--font-size-default);
		--button-spacing: 0;
		--box-shadow: none;
		--button-height: auto;
		color: inherit;
		display: inline-block;
		letter-spacing: inherit;
		text-transform: inherit;
		-webkit-text-decoration: underline;
		text-decoration: underline;
		transition-property: transform, opacity;
	}

	.button--medium {
		--button-height: 3rem;
		--font-size-button: var(--font-size-default);
		--button-spacing: var(--spacing--small);
	}

	.button--small {
		--button-height: 2rem;
		--button-spacing: var(--spacing--small);
		--font-size-button: var(--font-size-s);
		--loading-spinner-dimension: 1.25rem;
		padding-top: 0.2em;
	}

	.button--small svg {
			position: relative;
			top: 0.1em;
		}

	.button--small .loading-spinner {
			margin-top: -0.1rem;
		}
.back-button {
	--button-text-color: var(--color-blue);
	--button-background-color: var(--color-gold);
	--font-family-button: var(--font-family-bold);
	white-space: nowrap;
}
.back-button .icon {
		margin: 0 calc(0.5rem * 2) 0 0.5rem;
		margin: 0 calc(var(--spacing--small) * 2) 0 var(--spacing--small);
	}
.back-button--hidden {
		display: none;
	}
.checkbox {
	--checkmark-transition: 0.25s var(--ease-in-out-quad);
	--checkmark-visibility: 50;
	--checkmark-opacity-hover: 0;
	/* --checkbox-stroke-color: var(--color-blue--medium); */
	/* --checkbox-circle-fill-color: var(--color-white); */
	display: grid;
	grid-template-areas: 'prepend prepend' 'tag-left tag-right' 'error-left error-right' 'append append';
	grid-template-columns: auto 1fr;
	grid-template-rows: repeat(4, auto);
	column-gap: 1.5rem;
	grid-column-gap: 1.5rem;
	grid-column-gap: var(--spacing);
	column-gap: var(--spacing);
}
.checkbox + .checkbox {
		margin-top: 0;
	}
.checkbox--highlighted {
		--checkbox-stroke-color: var(--error-color);
	}
.checkbox--position-right {
		grid-template-columns: 1fr auto;
	}
.checkbox--checked {
		--checkmark-visibility: 0;
		--checkbox-stroke-color: var(--color-teal);
	}
.background-color--grey--medium .checkbox--checked {
			--checkbox-stroke-color: var(--color-blue);
		}
.checkbox:hover:not(.checkbox--checked) {
		--checkmark-opacity-hover: 0.5;
	}
.checkbox label {
		cursor: pointer;
		font-family: inherit;
	}
.checkbox .input__tag {
			display: none;
		}
.checkbox .input__tag:checked + label {
					--checkmark-visibility: 0;
				}
.checkbox .input__pseudo-checkbox {
			display: block;
			width: 3rem;
			height: 3rem;
			margin: 0.5rem 0;
			margin: var(--spacing--small) 0;
		}
.checkbox--position-left.checkbox .input__pseudo-checkbox {
				grid-area: tag-left;
			}
.checkbox--position-right.checkbox .input__pseudo-checkbox {
				grid-area: tag-right;
			}
.checkbox--alignment-start.checkbox .input__pseudo-checkbox {
				align-self: start;
			}
.checkbox--alignment-center.checkbox .input__pseudo-checkbox {
				align-self: center;
			}
.checkbox--alignment-end.checkbox .input__pseudo-checkbox {
				align-self: end;
			}
.checkbox .input__label {
			padding: 0;
			align-self: center;
			justify-self: start;
			max-width: 100%;
		}
.checkbox--position-left.checkbox .input__label {
				grid-area: tag-right;
			}
.checkbox--position-right.checkbox .input__label {
				grid-area: tag-left;
			}
.checkbox .input__error {
			display: none;
		}
.checkbox--error.checkbox .input__error {
				margin-top: 0.5rem;
				margin-top: var(--spacing--small);
				margin-bottom: 0.5rem;
				margin-bottom: var(--spacing--small);
				display: block;
			}
.checkbox .input__error {

			color: rgb(203, 0, 0);

			color: var(--error-color);
			font-size: 1rem;
			font-size: var(--font-size-default);
			transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			transition: opacity var(--input-transition);
}
.checkbox--position-left.checkbox .input__error {
				grid-area: error-right;
			}
.checkbox--position-right.checkbox .input__error {
				grid-area: error-left;
			}
.checkbox__prepend {
		grid-area: prepend;
	}
.checkbox__append {
		grid-area: append;
	}
.checkbox__svg {
		--checkbox-stroke-width: 2px;
		/* TODO: use dynamically calculated path lenght here */
		--checkbox-path-length: 50;
	}
.checkbox__svg polyline {
			stroke-dasharray: var(--checkbox-path-length);
			opacity: var(--checkbox-opacity);
			transition: var(--checkmark-transition);
			transition-property: stroke-dashoffset, opacity;
		}
.checkbox__svg polyline.checkbox-mark {
				stroke-dashoffset: var(--checkmark-visibility);
			}
.checkbox__svg polyline.checkbox-hover-mark {
				/* stroke-dashoffset: 0; */
				opacity: var(--checkmark-opacity-hover);
			}
.dashboard-tile {
    grid-column: span 1;
    position: relative;
    z-index: 1;
    min-height: var(--grid-item-height);
    background-color: rgb(27, 45, 57);
    background-color: var(--color-blue);
    padding: 1.5rem;
    padding: var(--spacing);
}
.dashboard-tile--disabled::before {
            content:'';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            background-color: rgba(72, 79, 84, 0.5);
        }
.dashboard-tile--disabled > div {
            opacity: 0.3;
        }
.dashboard-tile:hover:not(.dashboard-tile--disabled) {
            cursor: pointer;
        }
.dashboard-additional-information {
    border: 2px solid rgb(27, 45, 57);
    border: 2px solid var(--color-blue);
    padding: 1.5rem 1.5rem 1.5rem 0;
    padding: var(--spacing) var(--spacing) var(--spacing) 0;

}
.date-input {
	--input-transition: 0.25s var(--ease-in-out-quad);
	--border-color: rgba(var(--white), 0.2);
	--input-text-color: var(--color-white);
	--input-error-color: var(--error-color);
	display: grid;
	grid-template-areas: 'label' 'tag' 'error';
	grid-template-columns: auto;
	grid-auto-rows: -webkit-min-content;
	grid-auto-rows: min-content;
	/* no margin bottom needed, as error container has the var(--spacing) as min-height */
	margin-bottom: 0;
	max-width: 22.5rem;
	max-width: var(--input-width);
	width: 22.5rem;
	width: var(--input-width);
	isolation: isolate;
}
.date-input::after {
		content: '';
		display: block;
		grid-area: error;
		align-self: start;
		border-bottom: 2px solid var(--border-color);
		transition: border-color 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: border-color var(--input-transition);
	}
.date-input--highlighted {
		--border-color: var(--error-color);
	}
.date-input--highlighted-hint {
			--error-color: var(--color-grey);
		}
.date-input--disabled {
		--border-color: transparent;
	}
.date-input--hidden {
		display: none !important;
	}
.date-input--full-width {
		--input-width: 100%;
	}
.date-input__label {
		grid-area: label;
	}
.date-input__error {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		/* justify-self: end; */
		align-items: center;
		grid-area: error;
		pointer-events: none;
		color: rgb(203, 0, 0);
		color: var(--error-color);
		font-size: 1rem;
		font-size: var(--font-size-default);
		transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: opacity var(--input-transition);
		min-height: 1.5rem;
		min-height: var(--spacing);
	}
.date-input__error:empty::before {
				content: '.';
				display: block;
				opacity: 0;
			}
.date-input--disabled.date-input__error {
			display: none;
		}
.date-input__tag {
		grid-area: tag;
	}
.date-input__show-password {
		grid-area: show-password;
		transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: opacity var(--input-transition);
	}
.date-input--disabled .date-input__show-password {
			opacity: 0;
			pointer-events: none;
		}
.date-input__show-password.button {
			margin-left: 1em;
		}
.date-input .react-date-picker {
		position: relative;
		grid-area: tag;
	}
.date-input .react-date-picker__wrapper {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
		}
.date-input .react-date-picker__inputGroup {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			align-items: baseline;
			font-feature-settings: "tnum";
			font-variant-numeric: tabular-nums;
			width: 100%;
		}
.date-input .react-date-picker__inputGroup__input {
				color: var(--input-text-color);
				-webkit-text-fill-color: var(--input-text-color);
				opacity: 1; /* required on iOS */
				font-family: 'PxGroteskRegular';
				font-family: var(--font-family-regular);
				font-size: 1.125rem;
				font-size: var(--font-size-m);
				background-color: transparent;
				border: none;
				appearance: none;
				outline: none;
				padding: 0.5rem 0 0;
				overflow: hidden;
				text-overflow: ellipsis;
			}
.date-input .react-date-picker__inputGroup__input::-webkit-calendar-picker-indicator,
				.date-input .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
					display: none;
				}
/* remove background color from autofill input */
.date-input .react-date-picker__inputGroup__input:-webkit-autofill,
				.date-input .react-date-picker__inputGroup__input:-webkit-autofill:hover,
				.date-input .react-date-picker__inputGroup__input:-webkit-autofill:focus,
				.date-input .react-date-picker__inputGroup__input:-webkit-autofill:active {
					animation: autofill 0s forwards;
				}
.date-input .react-date-picker__inputGroup__day,
			.date-input .react-date-picker__inputGroup__month {
				/* min-width: 2ch; */
			}
.date-input .react-date-picker__inputGroup__year {
				/* min-width: 4ch; */
			}
.date-input .react-date-picker__inputGroup__leadingZero {
				color: var(--input-text-color);
				-webkit-text-fill-color: var(--input-text-color);
				opacity: 1; /* required on iOS */
				font-family: 'PxGroteskRegular';
				font-family: var(--font-family-regular);
				font-size: 1.125rem;
				font-size: var(--font-size-m);
			}
.date-input .react-date-picker__button {
			appearance: none;
			border: none;
			display: block;
			align-self: center;
			cursor: pointer;
		}
.date-input .react-date-picker__clear-button {
			/* grid-area: clear-button; */
			--delete-opacity: 0;
			--delete-pointer-events: none;
			position: relative;
			width: 1rem;
			height: 1rem;
			margin-left: 1rem;
			border-radius: 100%;
			background-color: rgb(127, 127, 127);
			background-color: var(--color-grey);
			transition: 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			transition: var(--input-transition);
			transition-property: opacity, background-color;
			opacity: 0;
			opacity: var(--delete-opacity);
			pointer-events: none;
			pointer-events: var(--delete-pointer-events);
			padding: 0;
			flex-shrink: 0;
		}
.date-input .react-date-picker__clear-button::before,
			.date-input .react-date-picker__clear-button::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				background-color: rgb(27, 45, 57);
				background-color: var(--color-blue);
				width: 0.5625rem;
				height: 1px;
			}
.date-input .react-date-picker__clear-button::before {
				transform: translateX(-50%) translateY(-50%) rotate(-45deg);
			}
.date-input .react-date-picker__clear-button::after {
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
			}
.date-input--dirty.date-input .react-date-picker__clear-button {
				--delete-opacity: 1;
				--delete-pointer-events: all;
			}
.date-input--disabled.date-input .react-date-picker__clear-button {
				--delete-opacity: 0;
				--delete-pointer-events: none;
			}
.date-input .react-date-picker__calendar-button {
			/* grid-area: calendar-button; */
			background-color: transparent;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			align-content: center;
			justify-content: center;
			place-content: center;
		}
.date-input .react-date-picker__calendar {
			right: 0 !important;
			left: auto !important;
			bottom: 100% !important;
			top: auto !important;
			top: initial !important;
		}
.date-input .react-date-picker__calendar {
			position: absolute;
			opacity: 0;
			/* important needed to overwrite inline js styles */
			z-index: 10;
			pointer-events: none;
			transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			transition: opacity 0.25s var(--ease-in-out-quad);
		}
.date-input .react-date-picker__calendar--open {
				opacity: 1;
				pointer-events: all;
			}
.date-input .react-calendar {
		--calendar-tile-text-color: currentColor;
		--calendar-tile-border-color: transparent;
		--calendar-tile-background-color: transparent;
		--calender-tile-dimension: 2rem;
		--calender-tile-gap: 0.25rem;
		--calendar-arrow-dimension: 1.125rem;
		background-color: rgb(255, 255, 255);
		background-color: var(--color-white);
		color: rgb(27, 45, 57);
		color: var(--color-blue);
		height: 20.5rem;
		padding: 1.25rem 1.75rem 0;
		position: relative;
		margin-bottom: 1.125rem;
		margin-bottom: var(--calendar-arrow-dimension);
	}
.date-input .react-calendar::after {
			content: '';
			position: absolute;
			bottom: 0;
			right: 6px;
			width: 0;
			height: 0;
			transform: translateY(100%);
			border-left: var(--calendar-arrow-dimension) solid transparent;
			border-right: var(--calendar-arrow-dimension) solid transparent;
			border-top: var(--calendar-arrow-dimension) solid #ffffff;
		}
.date-input .react-calendar__navigation {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
		}
.date-input .react-calendar__navigation button {
				appearance: none;
				background-color: transparent;
				border: none;
			}
.date-input .react-calendar__navigation__label {
				color: rgb(27, 45, 57);
				color: var(--color-blue);
				padding: 0;
				flex-grow: 1;
			}
.date-input .react-calendar__navigation__arrow {
				padding: 0 0.75rem;
				cursor: pointer;
			}
.date-input .react-calendar__viewContainer {
			width: calc(var(--calender-tile-dimension) * 7 + var(--calender-tile-gap) * 7);
		}
.date-input .react-calendar__month-view__weekdays {
			margin: 1.125rem 0;
		}
.date-input .react-calendar__month-view__weekdays__weekday {
				text-align: center;
				opacity: 0.35;
				font-size: 0.75rem;
				font-size: var(--font-size-s);
			}
.date-input .react-calendar__month-view__weekdays__weekday abbr {
					-webkit-text-decoration: none;
					text-decoration: none;
				}
.date-input .react-calendar__month-view__days__day {
				flex-basis: var(--calender-tile-dimension) !important;
				max-width: var(--calender-tile-dimension) !important;
			}
.date-input .react-calendar__month-view__days__day {
				appearance: none;
				border: 1px solid var(--calendar-tile-border-color);
				background-color: var(--calendar-tile-background-color);
				color: var(--calendar-tile-text-color);
				border-radius: 50%;
				height: var(--calender-tile-dimension);
				padding: 0;
				margin: calc(var(--calender-tile-gap) / 2);
				cursor: pointer;
			}
.date-input .react-calendar__month-view__days__day.react-calendar__tile--now {
						--calendar-tile-border-color: var(--color-blue);
					}
.date-input .react-calendar__month-view__days__day.react-calendar__tile--active {
						--calendar-tile-border-color: transparent;
						--calendar-tile-background-color: var(--color-gold);
						--calendar-tile-text-color: var(--color-white);
					}
.date-input .react-calendar__month-view__days__day[disabled] {
					opacity: 0.25;
				}
.date-input .react-calendar__month-view__days__day--neighboringMonth {
				pointer-events: none;
				opacity: 0;
			}
@keyframes autofill {
	100% {
		background: transparent;
		color: inherit;
	}
}
.error {
	--error-transition: 0.25s var(--ease-in-out-quad);
	display: grid;
	max-width: 22.5rem;
	max-width: var(--input-width);
	color: rgb(203, 0, 0);
	color: var(--error-color);
	transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: opacity var(--error-transition);
	grid-template-columns: -webkit-min-content 1fr;
	grid-template-columns: min-content 1fr;
	grid-template-areas: 'icon message';
	align-items: center;
	opacity: 0;
}
.error--visible {
		opacity: 1;
	}
.error__messages {
		grid-area: message;
	}
.error__icon {
		width: 3.5rem;
		height: 3.5rem;
		grid-area: icon;
		align-self: center;
		justify-self: end;
	}
.form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
}
.breakpoint-medium .form {
		width: -moz-fit-content;
		width: -webkit-fit-content;
		width: fit-content;
	}
.form .error {
		margin-top: 1.5rem;
		margin-top: var(--spacing);
	}
.form .submit {
		align-self: center;
	}
.google-maps-marker {
	transform: translate(-50%, -50%);
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}
.google-places-autocomplete {
	position: relative;
}
.google-places-autocomplete__list {
		position: absolute;
		width: 100%;
		top: 100%;
		left: 0;
		background-color: rgb(47, 69, 84);
		padding: 0;
		z-index: 1000;
	}
.google-places-autocomplete__list-item {
		padding: 1rem;
		padding: var(--spacing--small-regular);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		border-bottom: 1px solid rgba(var(--white), 0.1);
		cursor: pointer;
		position: relative;
	}
.google-places-autocomplete__list-item:hover {
			background-color: rgba(255, 255, 255, 0.15);
			background-color: rgba(var(--white), 0.15);
		}
.icon {
	--icon-size: unset;
	width: initial;
	width: var(--icon-size);
	height: auto;
	max-width: 75vw;
	max-height: 75vh;
	flex-shrink: 0;
}
.icon-size--extra-small {
			--icon-size: 0.35rem;
		}
.icon-size--small {
			--icon-size: 0.75rem;
		}
.icon-size--medium {
			--icon-size: 1.25rem;
		}
.icon-size--large {
			--icon-size: 4.5rem;
		}
.icon-size--extra-large {
			--icon-size: 20em;
		}
.icon-size--max {
			--icon-size: 30em;
		}
/* & * {
		vector-effect: non-scaling-stroke;
	} */
.icon--settings {
		--svg-color-alternate: var(--color-blue);
		--animation-play-state: paused;
	}
.icon--settings:hover {
			--animation-play-state: running;
		}
.icon--settings ellipse {
			animation-play-state: var(--animation-play-state);
			animation-name: settings-animation;
			animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
			animation-timing-function: var(--ease-in-out-quad);
			animation-duration: 1.75s;
			animation-iteration-count: infinite;
			animation-direction: alternate;
			outline-color: var(--svg-color-alternate);
			outline-width: 0.1875rem;
			outline-style: solid;
		}
.icon--settings ellipse:nth-of-type(1) {
				animation-duration: 1.5s;
			}
.icon--settings ellipse:nth-of-type(2) {
				animation-duration: 2s;
				animation-direction: alternate-reverse;
			}
@keyframes settings-animation {
	0% {
		transform: translateX(0);
	}

	33% {
		transform: translateX(-0.625rem);
	}

	66% {
		transform: translateX(0.625rem);
	}

	100% {
		transform: translateX(0);
	}
}
.loading-spinner {
	--scoped-loading-spinner-dimension: var(--loading-spinner-dimension, 2rem);
	--loading-spinner-dash-offset: 187;
	--loading-spinner-dash-offset-midway: 46.75;
	--loading-spinner-animation-duration: 1.4s;
	--scoped-loading-spinner-color-1: var(--loading-spinner-color-1, var(--color-white));
	--scoped-loading-spinner-color-2: var(--loading-spinner-color-2, var(--color-gold));
	position: absolute;
	display: block;
	z-index: 100000000;
	width: 2rem;
	width: var(--scoped-loading-spinner-dimension);
	height: 2rem;
	height: var(--scoped-loading-spinner-dimension);
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	opacity: 0;
	transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: opacity 0.25s var(--ease-in-out-quad);
	pointer-events: none;
	--animation-name-wrapper: none;
	--animation-name-dash: none;
	--animation-name-colors: none;
}
.loading-spinner--default {
		left: auto;
		top: 1.5rem;
		top: var(--spacing);

		/* .breakpoint-medium & { */
		/* } */
	}
/* right: 0; */
/* transform: translateX(calc(15vw / -2)) translateX(50%) translateY(var(--header-height)); */
@supports (padding: env(safe-area-inset-top)) {
.loading-spinner--default {
			top: calc(1.5rem + env(safe-area-inset-top) * -1);
			top: calc(var(--spacing) + env(safe-area-inset-top) * -1);
	}
		}
.loading-spinner--default {
		right: 1.5rem;
		right: var(--spacing);
		transform: translateY(-50%) translateY(calc(var(--header-height) / 2));
}
.loading-spinner--center {
		position: fixed;
		--scoped-loading-spinner-dimension: 5rem;

		/* .breakpoint-medium & { */
		margin-left: calc(var(--navigation-width--static) / 2);
		/* } */
	}
.loading-spinner--visible {
		opacity: 1;
	}
.loading-spinner--animate {
		--animation-name-wrapper: loading-spinner-rotator;
		--animation-name-dash: loading-spinner-dash;
		--animation-name-colors: loading-spinner-colors;
	}
.loading-spinner--mfa {
		top: 1.5rem;
		top: var(--spacing);
		left: 1.5rem;
		left: var(--spacing);
		transform: none;
	}
.loading-spinner__wrapper {
		width: 100%;
		height: 100%;
		animation: var(--animation-name-wrapper) var(--loading-spinner-animation-duration) linear infinite;
	}
.loading-spinner__circle {
		stroke-dasharray: var(--loading-spinner-dash-offset);
		stroke-dashoffset: 0;
		transform-origin: center;
		animation: var(--animation-name-dash) var(--loading-spinner-animation-duration) ease-in-out infinite,
			var(--animation-name-colors) calc(var(--loading-spinner-animation-duration) * 4) ease-in-out infinite;
	}
/* changing opacity is required to prevent glitch in safari */
@keyframes loading-spinner-rotator {
	0% {
		transform: rotate(0deg);
		opacity: 0;
	}

	10% {
		opacity: 1;
	}

	90% {
		opacity: 1;
	}

	100% {
		transform: rotate(270deg);
		opacity: 0;
	}
}
@keyframes loading-spinner-colors {
	0% {
		stroke: var(--scoped-loading-spinner-color-1);
	}

	25% {
		stroke: var(--scoped-loading-spinner-color-2);
	}

	50% {
		stroke: var(--scoped-loading-spinner-color-1);
	}

	75% {
		stroke: var(--scoped-loading-spinner-color-2);
	}

	100% {
		stroke: var(--scoped-loading-spinner-color-1);
	}
}
@keyframes loading-spinner-dash {
	0% {
		stroke-dashoffset: var(--loading-spinner-dash-offset);
	}

	50% {
		stroke-dashoffset: var(--loading-spinner-dash-offset-midway);
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: var(--loading-spinner-dash-offset);
		transform: rotate(450deg);
	}
}
.modal {
	--modal-animation-duration: 0.4s;
	--modal-animation-timing-function: var(--ease-in-out-quad);
	--modal-background-color: var(--color-white);
	--modal-close-icon-color: var(--color-blue--medium);
	--modal-close-icon-background-color: var(--color-grey--medium);
	--modal-top: 50%;
	--modal-top-translate: -50%;
	--modal-window-max-width: 32rem;
	--modal-border-radius: 0.25rem;
	--modal-header-color: var(--color-blue--medium);
	--modal-content-color: var(--color-blue--medium);
	--scrollbar-background: var(--modal-background-color);
	--modal-padding: 2rem;
	display: none;
	margin: 0 auto;
	background: rgb(255, 255, 255);
	background: var(--modal-background-color);
	width: calc(100vw - 0.5rem * 2);
	width: calc(100vw - var(--spacing--small) * 2);
	max-width: 32rem;
	max-width: var(--modal-window-max-width);
	max-height: calc(100% - 1.5rem * 2);
	max-height: calc(100% - var(--spacing) * 2);
	border-radius: 0.25rem;
	border-radius: var(--modal-border-radius);
	border: none;
	padding: 2rem;
	padding: var(--modal-padding);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
	box-shadow: var(--box-shadow-bottom--5-steps);
	animation-duration: 0.4s;
	animation-duration: var(--modal-animation-duration);
	animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	animation-timing-function: var(--ease-in-out-quad);
	position: fixed;
	top: 50%;
	top: var(--modal-top);
	transform: translateY(-50%);
	transform: translateY(var(--modal-top-translate));
	will-change: transform;
	align-content: center;
	justify-content: center;
	place-content: center;
	overflow-x: hidden;
	overflow: auto;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
	--error-color: var(--color-red--medium);

	--input-primary-color: var(--blue);
	--input-secondary-color: var(--gold);
	--input-tertiary-color: var(--grey);

	--checkbox-stroke-color: var(--color-blue--medium);
}
.modal--size-large {
		--modal-window-max-width: 60rem;
	}
.modal--position-bottom {
		--modal-top: calc(100% - var(--spacing));
		--modal-top-translate: -100%;
	}
.modal--position-top-right {
		--modal-top: calc(0% + var(--spacing));
		--modal-top-translate: 0%;
		margin-right: 1.5rem;
		margin-right: var(--spacing);
	}
.modal--default {
		--modal-window-max-width: 48rem;
	}
.modal--success {
		--modal-background-color: var(--color-teal);
		--modal-window-max-width: 48rem;
	}
@supports (padding: env(safe-area-inset-top)) {
.client-is-ios .modal {
			max-height: calc(100% - 0.5rem * 2);
			max-height: calc(100% - var(--spacing--small) * 2)
	}

			.client-is-installed.client-is-ios .modal {
				max-height: calc(100% - env(safe-area-inset-top));
			}

			.client-is-ios .modal--position-bottom {
				top: calc(var(--modal-top) - env(safe-area-inset-bottom));
			}

			.client-is-ios .modal--position-top-right {
				top: calc(var(--modal-top) + env(safe-area-inset-top));
			}
		}
.modal[open] {
		display: block;
		animation-name: show-dialog;
	}
.modal[open]::backdrop {
			animation-name: show-backdrop;
		}
.modal.hide {
		animation-name: hide-dialog;
	}
.modal.hide::backdrop {
			animation-name: hide-backdrop;
		}
.modal::backdrop {
		animation-name: none;
		/* using a custom property to set the duration does not work somehow */
		animation-duration: 0.4s;
		animation-fill-mode: forwards;
		animation-timing-function: var(--modal-animation-timing-function);
	}
.modal__header {
		grid-area: top;
		display: grid;
		grid-template-columns: auto 1fr;
		padding: 0;
		color: var(--modal-header-color);
		position: sticky;
		top: 0;
		isolation: isolate;
		z-index: 10;
	}
.modal__header::before {
			content: '';
			position: absolute;
			top: calc(var(--modal-padding) * -1);
			right: calc(var(--modal-padding) * -1);
			bottom: 0;
			left: calc(var(--modal-padding) * -1);
			z-index: -1;
			background-color: var(--modal-background-color);
			opacity: 0.9;
			/* mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, transparent 80%); */
			/* mask-image: linear-gradient(to bottom, black 80%, transparent 100%); */
		}
.modal__header:empty {
			display: none;
		}
.modal__content {
		grid-area: center;
		color: var(--modal-content-color);
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex-direction: column;
	}
.modal__content > *:first-child {
			margin-top: 0.5rem;
			margin-top: var(--spacing--small);
		}
.modal__content > *:last-child {
			margin-bottom: 0;
		}
.modal__title {
		grid-column: 2 / 3;
		grid-row: 1 / -1;
		font-family: 'PxGroteskBold';
		font-family: var(--font-family-bold);
		font-size: 1.3125rem;
		font-size: var(--font-size-l);
		padding: 0 1.5rem 1.5rem 0;
		padding: 0 var(--spacing) var(--spacing) 0;
	}
.modal__close {
		font-size: 0;
		margin-left: 0.5rem;
		margin-left: var(--spacing--small);
		position: relative;
		cursor: pointer;
		grid-column: 3 / 4;
		grid-row: 1 / -1;
		justify-self: end;
		align-self: start;
		border-radius: 999rem;
		border-radius: var(--border-radius--round);
		background-color: var(--modal-close-icon-background-color);
		transition: background-color 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: background-color 0.15s var(--ease-in-out-quad);
	}
.modal__close:hover {
			--modal-close-icon-color: var(--color-grey--medium);
			--modal-close-icon-background-color: var(--color-blue--medium);
		}
.modal__close svg {
			width: 18px;
			height: 18px;
			margin: 0.25rem;
			margin: var(--spacing--very-small);
		}
.modal__close::before {
			content: '';
			position: absolute;
			top: calc(var(--modal-padding) * -1);
			right: calc(var(--modal-padding) * -1);
			bottom: calc(var(--modal-padding) * -1);
			left: calc(var(--modal-padding) * -1);
			z-index: 10;
		}
.modal__button {
		position: sticky;
		bottom: 0;
		margin: 1.5rem auto 0;
		margin: var(--spacing) auto 0;
	}
.modal hr {
		--hr-line-color: var(--modal-content-color);
	}
@keyframes show-dialog {
	from {
		opacity: 0;
		transform: translateY(var(--modal-top-translate)) translateY(2rem);
	}

	to {
		opacity: 1;
		transform: translateY(var(--modal-top-translate));
	}
}
@keyframes hide-dialog {
	to {
		opacity: 0;
		transform: translateY(var(--modal-top-translate)) translateY(2rem);
	}
}
@keyframes show-backdrop {
	from {
		-webkit-backdrop-filter: blur(0);
		        backdrop-filter: blur(0);
	}

	to {
		-webkit-backdrop-filter: blur(10px);
		        backdrop-filter: blur(10px);
	}
}
@keyframes hide-backdrop {
	from {
		-webkit-backdrop-filter: blur(10px);
		        backdrop-filter: blur(10px);
	}

	to {
		-webkit-backdrop-filter: blur(0);
		        backdrop-filter: blur(0);
	}
}
/* polyfill */
.modal[role='dialog'] {
	left: 0;
	right: 0;
}
.modal[role='dialog']:not([open]) {
		display: none;
	}
.modal[role='dialog'] + .backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		animation-name: none;
		/* using a custom property to set the duration does not work somehow */
		animation-duration: 0.4s;
		animation-fill-mode: forwards;
		animation-timing-function: var(--modal-animation-timing-function);
	}
.modal[role='dialog'][open] + .backdrop {
			animation-name: show-backdrop;
		}
.modal[role='dialog'].hide + .backdrop {
			animation-name: hide-backdrop;
		}
._dialog_overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.radiobutton {
	--radiobutton-transition: 0.25s var(--ease-in-out-quad);
	--radiobutton-visibility: 0.3;
	--radiobutton-opacity: 0.2;
	display: grid;
	grid-template-areas: 'tag-left tag-right';
	column-gap: 1.5rem;
	grid-column-gap: 1.5rem;
	grid-column-gap: var(--spacing);
	column-gap: var(--spacing);
	grid-template-columns: -webkit-max-content 1fr;
	grid-template-columns: max-content 1fr;
}
.radiobutton--highlighted {
		--radiobutton-stroke-color: var(--error-color);
	}
.radiobutton--checked {
		--radiobutton-visibility: 1;
		--radiobutton-opacity: 1;
	}
.radiobutton:hover:not(.radiobutton--checked) {
		--radiobutton-visibility: 0.5;
		--radiobutton-opacity: 0.5;
	}
.radiobutton label {
		cursor: pointer;
		font-family: var(--font-alternative-light);
		font-weight: normal;
	}
.radiobutton .input__tag {
			display: none;
		}
.radiobutton .input__tag:checked ~ label {
				--radiobutton-visibility: 1;
				--radiobutton-opacity: 1;
			}
.radiobutton .input__pseudo-radiobutton {
			display: block;
			width: 1.5em;
			width: var(--icon-size, 1.5em);
			height: 1.5em;
			height: var(--icon-size, 1.5em);
		}
.radiobutton--position-left.radiobutton .input__pseudo-radiobutton {
				grid-area: tag-left;
			}
.radiobutton--position-right.radiobutton .input__pseudo-radiobutton {
				grid-area: tag-right;
			}
.radiobutton .input__label {
			padding: 0;
			align-self: center;
		}
.radiobutton--position-left.radiobutton .input__label {
				grid-area: tag-right;
			}
.radiobutton--position-right.radiobutton .input__label {
				grid-area: tag-left;
			}
.radiobutton__svg {
		--radiobutton-stroke-width: 2px;
	}
.radiobutton__svg .radiobutton__inner-circle {
			opacity: var(--radiobutton-opacity);
			transform: scale(var(--radiobutton-visibility));
			transform-origin: 50% 50%;
			transition: var(--radiobutton-transition);
			transition-property: transform, opacity;
		}
.radiobutton-group {
	margin: 1.5rem 0;
	margin: var(--spacing) 0;
}
.radiobutton-group__headline {
		margin-top: 0;
	}
.radiobutton-group--large .radiobutton-group__headline {
			margin-bottom: 2rem;
		}
.radiobutton-group__error {
		color: rgb(203, 0, 0);
		color: var(--error-color);
		font-size: 1rem;
		font-size: var(--font-size-default);
		transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: opacity var(--input-transition);
		display: block;
	}
.radiobutton-group__error:not(:empty) {
			margin-top: 1.5rem;
			margin-top: var(--spacing);
		}
.radiobutton-group__error:empty::before {
				content: '.';
				display: block;
				opacity: 0;
			}
.radiobutton-group__wrapper {
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		max-width: 100%;
		display: grid;
		gap: 1.5rem;
		grid-gap: 1.5rem;
		grid-gap: var(--spacing);
		gap: var(--spacing);
	}
.radiobutton-group--column .radiobutton-group__wrapper {
			max-width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			flex-wrap: wrap;
			gap: 3rem;
			gap: var(--spacing--large);
		}
.radiobutton-group--large {
		--icon-size: 3rem;
	}
.radiobutton-group--switchmode {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
		position: relative;
	}
.radiobutton-group--switchmode::after {
			background-color: #000000;
			opacity: 0.333;
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;
			top: 0;
			left: 0;
			border-radius: 2rem;
		}
.radiobutton-group--switchmode .input__pseudo-radiobutton {
				display: none;
			}
.select-input {
	width: 100%;
	display: grid;
	grid-template-areas: 'label' 'value' 'line' 'error';
	grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content -webkit-min-content;
	grid-template-rows: min-content min-content min-content min-content;
	position: relative;
	--border-color: rgba(var(--white), 0.2);
	--input-text-color: var(--color-white);
	--input-font-size: var(--font-size-m);
	--svg-color: var(--color-white);
}
.select-input.input--highlighted {
		--border-color: var(--error-color);
	}
.select-input.input--disabled {
		--border-color: transparent;
		--svg-color: transparent;
		/* --input-text-opacity: 0.3; */
	}
.select-input::after {
		content: '';
		display: block;
		grid-area: line;
		align-self: start;
		border-bottom: 2px solid var(--border-color);
		transition: border-color 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: border-color var(--input-transition);
	}
.select-input select {
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
	}
.select-input select:not([disabled]) {
			cursor: pointer;
		}
.select-input__label {
		grid-area: label;
	}
.select-input__value {
		grid-area: value;
		position: relative;
		width: 100%;
		color: var(--input-text-color);
		font-size: var(--input-font-size);
		font-family: 'PxGroteskRegular';
		font-family: var(--font-family-regular);
		padding: 0.5rem 2ch 0 0;
		cursor: pointer;
		opacity: 1;
		opacity: var(--input-text-opacity, 1);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
.select-input__value--nothing-selected {
			--input-text-opacity: 0.1;
		}
.select-input__value svg {
			position: absolute;
			right: 0;
			transform: rotate(90deg) scale(0.5);
		}
.select-input__error {
		grid-area: error;
		color: rgb(203, 0, 0);
		color: var(--error-color);
		font-size: 1rem;
		font-size: var(--font-size-default);
		transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: opacity var(--input-transition);
		min-height: 1.5rem;
		min-height: var(--spacing);
	}
.select-input__error:empty::before {
				content: '.';
				display: block;
				opacity: 0;
			}
.input {
	--border-color: rgba(var(--input-primary-color), 0.2);
	--input-text-color: rgb(var(--input-primary-color));
	--input-margin-vertical: var(--spacing);
}
.input:focus-within {
		--border-color: rgb(var(--input-primary-color));
	}
.input:not(.input--hidden) + .input {
		--input-margin-vertical: 0;
	}
.input--highlighted {
		--border-color: var(--error-color);
	}
.input--highlighted-hint {
			--error-color: rgb(var(--input-tertiary-color));
		}
.input--disabled,
	.input--read-only {
		--border-color: transparent;
		--input-text-opacity: 0.3;
		pointer-events: none;
	}
.input--hidden {
		visibility: hidden !important;
		margin: 0 !important;
		width: 0 !important;
	}
.input--hidden {
		height: 0;
		overflow: hidden;
		pointer-events: none;
		z-index: -1;
	}
.input--full-width {
		--input-width: 100%;
	}
.input--text {
		display: grid;
		grid-template-areas: '. prepend prepend prepend prepend' 'icon label label label label' 'icon prefix tag show-password suffix' '. . error error .' '. append append append append';
		grid-template-columns: auto auto 1fr auto auto;
		grid-template-rows: auto auto 1fr auto auto;
		/* no margin bottom needed, as error container has the var(--spacing) as min-height */
		margin-top: var(--input-margin-vertical);
		margin-bottom: 0;
		max-width: 100%;
		width: 22.5rem;
		width: var(--input-width);
	}
.input--text::after {
			content: '';
			display: block;
			grid-area: error;
			align-self: start;
			border-bottom: 2px solid var(--border-color);
			transition: border-color 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			transition: border-color var(--input-transition);
		}
.input--text .input__icon {
			grid-area: icon;
			align-self: end;
		}
.input--text .input__label {
			grid-area: label;
			color: rgb(180, 150, 83);
			color: rgb(var(--input-secondary-color));
		}
.input--text .input__error {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			/* justify-self: end; */
			align-items: center;
			grid-area: error;
			pointer-events: none;
			color: rgb(203, 0, 0);
			color: var(--error-color);
			font-size: 1rem;
			font-size: var(--font-size-default);
			transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			transition: opacity var(--input-transition);
			min-height: 1.5rem;
			min-height: var(--spacing);
		}
.input--text .input__error:empty::before {
					content: '.';
					display: block;
					opacity: 0;
				}
.input--disabled:not(.visually-hidden).input--text .input__error,
			.input--read-only.input--text .input__error {
				display: none;
			}
.input--text .input__tag {
			grid-area: tag;
			color: var(--input-text-color);
			/* -webkit-text-fill-color: var(--input-text-color); */
			opacity: 1;
			opacity: var(--input-text-opacity, 1); /* required on iOS */
			font-family: 'PxGroteskRegular';
			font-family: var(--font-family-regular);
			font-size: 1.125rem;
			font-size: var(--font-size-m);
			background-color: transparent;
			border: none;
			appearance: none;
			outline: none;
			padding: 0.5rem 0 0;
			overflow: hidden;
			text-overflow: ellipsis;
		}
.input--text .input__tag::-webkit-calendar-picker-indicator,
			.input--text .input__tag::-webkit-inner-spin-button {
				display: none;
			}
/* remove background color from autofill input */
.input--text .input__tag:-webkit-autofill,
			.input--text .input__tag:-webkit-autofill:hover,
			.input--text .input__tag:-webkit-autofill:focus,
			.input--text .input__tag:-webkit-autofill:active {
				background-color: transparent;
				-webkit-text-fill-color: var(--input-text-color);
				-webkit-box-shadow: 0 0 0 1000px rgb(16, 32, 42) inset;
				-webkit-box-shadow: 0 0 0 1000px var(--color-blue--medium) inset;
				-webkit-transition: background-color 5000s ease-in-out 0s;
				transition: background-color 5000s ease-in-out 0s;
				caret-color: var(--input-text-color);
			}
.input--text .input__tag::placeholder {
				color: rgba(255, 255, 255, 0.1);
				color: rgba(var(--input-primary-color), 0.1);
			}
.input--text .input__delete {
			--delete-opacity: 0;
			--delete-pointer-events: none;
			grid-area: tag;
			display: block;
			position: relative;
			align-self: center;
			justify-self: end;
			place-self: center end;
			width: 1rem;
			height: 1rem;
			margin-left: 1rem;
			border-radius: 100%;
			background-color: rgb(255, 255, 255);
			background-color: rgb(var(--input-primary-color));
			transition: 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			transition: var(--input-transition);
			transition-property: opacity, background-color;
			opacity: 0;
			opacity: var(--delete-opacity);
			pointer-events: none;
			pointer-events: var(--delete-pointer-events);
			cursor: pointer;
		}
.input--text .input__delete::before,
			.input--text .input__delete::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				background-color: rgb(180, 150, 83);
				background-color: rgb(var(--input-secondary-color));
				width: 0.5625rem;
				height: 1px;
			}
.input--text .input__delete::before {
				transform: translateX(-50%) translateY(-50%) rotate(-45deg);
			}
.input--text .input__delete::after {
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
			}
.input--dirty.input--text .input__delete {
				--delete-opacity: 1;
				--delete-pointer-events: all;
			}
.input--disabled.input--text .input__delete,
			.input--read-only.input--text .input__delete {
				--delete-opacity: 0;
				--delete-pointer-events: none;
			}
.input__prepend {
		grid-area: prepend;
	}
.input__append {
		grid-area: append;
	}
.input__prefix {
		grid-area: prefix;
		padding: 0.5rem 0 0;
		margin-right: 1em;
		font-size: 1.125rem;
		font-size: var(--font-size-m);
	}
.input__suffix {
		grid-area: suffix;
		padding: 0.5rem 0 0;
		margin-left: 1em;
		font-size: 1.125rem;
		font-size: var(--font-size-m);
	}
.input__show-password {
		grid-area: show-password;
		transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: opacity var(--input-transition);
	}
.input--disabled .input__show-password,
		.input--read-only .input__show-password {
			opacity: 0;
			pointer-events: none;
		}
.input__show-password.button {
			margin-left: 1em;
			margin-bottom: 0;
			margin-top: 0;
		}
.textarea {
	--border-color: rgba(var(--white), 0.2);
	--input-text-color: var(--color-white);
	--input-error-color: var(--error-color);
	--input-margin-vertical: var(--spacing);
	display: grid;
	grid-template-areas: 'label' 'tag' 'error';
	grid-template-columns: 1fr;
	grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content;
	grid-template-rows: min-content min-content min-content;
	/* no margin bottom needed, as error container has the var(--spacing) as min-height */
	margin-top: 1.5rem;
	margin-top: var(--input-margin-vertical);
	margin-bottom: 0;
	width: 100%;
}
.textarea:focus-within {
		--border-color: var(--color-white);
	}
.textarea:not(.textarea--hidden) + .input {
		--input-margin-vertical: 0;
	}
.textarea--highlighted {
		--border-color: var(--error-color);
	}
.textarea--disabled,
	.textarea--read-only {
		--border-color: transparent;
		--input-text-opacity: 0.3;
		pointer-events: none;
	}
.textarea--hidden {
		visibility: hidden !important;
		margin: 0 !important;
		width: 0 !important;
	}
.textarea--hidden {
		height: 0;
		overflow: hidden;
		pointer-events: none;
		z-index: -1;
	}
.textarea__label {
		grid-area: label;
	}
.textarea__error {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		/* justify-self: end; */
		align-items: center;
		grid-area: error;
		pointer-events: none;
		color: rgb(203, 0, 0);
		color: var(--error-color);
		font-size: 1rem;
		font-size: var(--font-size-default);
		transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: opacity var(--input-transition);
		min-height: 1.5rem;
		min-height: var(--spacing);
	}
.textarea__error:empty::before {
				content: '.';
				display: block;
				opacity: 0;
			}
.textarea--disabled.textarea__error,
		.textarea--read-only.textarea__error {
			display: none;
		}
.textarea__tag {
		grid-area: tag;
		color: var(--input-text-color);
		/* -webkit-text-fill-color: var(--input-text-color); */
		opacity: 1;
		opacity: var(--input-text-opacity, 1); /* required on iOS */
		font-family: 'PxGroteskRegular';
		font-family: var(--font-family-regular);
		font-size: 1.125rem;
		font-size: var(--font-size-m);
		background-color: transparent;
		border: none;
		appearance: none;
		outline: none;
		padding: 0.5rem;
		margin-top: 0.5rem;
		margin-top: var(--spacing--small);
		resize: vertical;
		border: 2px solid var(--border-color);
	}
.textarea__tag::-webkit-calendar-picker-indicator,
		.textarea__tag::-webkit-inner-spin-button {
			display: none;
		}
/* remove background color from autofill input */
.textarea__tag:-webkit-autofill,
		.textarea__tag:-webkit-autofill:hover,
		.textarea__tag:-webkit-autofill:focus,
		.textarea__tag:-webkit-autofill:active {
			background-color: transparent;
			-webkit-text-fill-color: var(--input-text-color);
			-webkit-box-shadow: 0 0 0 1000px rgb(16, 32, 42) inset;
			-webkit-box-shadow: 0 0 0 1000px var(--color-blue--medium) inset;
			-webkit-transition: background-color 5000s ease-in-out 0s;
			transition: background-color 5000s ease-in-out 0s;
			caret-color: #ffffff;
		}
.textarea__tag::placeholder {
			color: rgba(255, 255, 255, 0.1);
			color: rgba(var(--white), 0.1);
		}
.tooltip {
	color: currentColor;
	--tooltip-icon-color: currentColor;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: bottom;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
}
.tooltip svg {
		order: -1;
		padding-right: 0.5rem;
		padding-right: var(--spacing--small);
	}
.tooltip__label {
		-webkit-text-decoration: underline;
		text-decoration: underline;
	}
.version-number {
	font-size: 0.75rem;
	font-size: var(--font-size-s);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	opacity: 0.2;
	padding: 0.25rem 0.5rem 0.25rem 0;
	padding: var(--spacing--very-small) var(--spacing--small) var(--spacing--very-small) 0;
}
.horizontal-rule {
	min-height: 2rem;
}
.notice {
	display: grid;
	grid-template-columns: -webkit-max-content 1fr;
	grid-template-columns: max-content 1fr;
	grid-gap: 1.5rem;
	grid-gap: var(--spacing);
}
.notice--warn {
		--svg-color: var(--color-gold);
		color: rgb(180, 150, 83);
		color: var(--color-gold);
	}
.notice--ok {
		--svg-color:var(--color-teal);
		color:rgba(1, 254, 161);
		color:var(--color-teal);
	}
.image {
	display: block;
	flex-shrink: 0;
	position: relative;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
	height: -moz-fit-content;
	height: -webkit-fit-content;
	height: fit-content;
}
.image svg {
		position: absolute;
		bottom: 0.5rem;
		bottom: var(--spacing--small);
		right: 0.5rem;
		right: var(--spacing--small);
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1);
		box-shadow: var(--box-shadow-bottom--3-steps);
		border-radius: 50%;
	}
.PhoneInputCountry {
	grid-area: prefix;
	position: relative;
	align-self: stretch;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	padding: 0 2ch 0 0;
	margin-right: 0.5em;
}
.PhoneInputCountry > svg {
		position: absolute;
		right: 0;
		transform: rotate(90deg) scale(0.5);
	}
.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}
.PhoneInputCountryIconImg {
	width: 1em;
}
.timeline__entry {
        position: relative;
        display: grid;
        width: 100%;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        min-height: 7rem;
        grid-template-areas: 'general separator details';
        grid-template-columns:
            minmax(100px, 0.3fr) auto minmax(100px, 0.7fr);
        grid-template-rows: auto;
        align-items:flex-start;
        border-width: 0rem;
        column-gap: 0.5rem;
        grid-column-gap: 0.5rem;
        grid-column-gap: var(--spacing--small);
        column-gap: var(--spacing--small);
        row-gap: 0.5rem;
        grid-row-gap: 0.5rem;
        grid-row-gap: var(--spacing--small);
        row-gap: var(--spacing--small);
        padding: 0rem;
        margin-bottom: 0rem;
        color: rgb(255, 255, 255);
        color: var(--color-white);
        font-family: 'PxGroteskLight';
        font-family: var(--font-family-light);
        z-index: 0;
    }
.timeline__entry__general {
            grid-area: general;
            text-align: right;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
.timeline__entry__general__type {
                font-size: 1.3125rem;
                font-size: var(--font-size-l);
		        font-weight: bold;
                
            }
.timeline__entry__general__date {
                font-size: 1.125rem;
                font-size: var(--font-size-m);
		        color: rgb(180, 150, 83);
		        color: var(--color-gold);
            }
.timeline__entry__general__time {
                font-size: 0.75rem;
                font-size: var(--font-size-s);
            }
.timeline__entry__separator {
            grid-area: separator;
            height: 100%;
            width: 72px;
            background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(180, 150, 83)), to(rgb(180, 150, 83)));
            background-image: linear-gradient(rgb(180, 150, 83), rgb(180, 150, 83));
            background-image: -webkit-gradient(linear, left top, left bottom, from(var(--color-gold)), to(var(--color-gold)));
            background-image: linear-gradient(var(--color-gold), var(--color-gold));
                background-size: 0.375rem 100%;
                background-repeat: no-repeat;
                background-position: center center;

        }
.timeline__entry__separator__icon {
                background-color: rgb(16, 32, 42);
                background-color: var(--color-blue--medium);
                width: -webkit-min-content;
                width: -moz-min-content;
                width: min-content;
                height: -webkit-min-content;
                height: -moz-min-content;
                height: min-content;
            }
.timeline__entry__details {
            grid-area: details;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
.timeline__document-entry {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
    }
.timeline__document-entry__name {
            display: inline-block;
            font-size: 1.3125rem;
            font-size: var(--font-size-l);
            font-weight: bold;
        }
.timeline__document-entry__buttons {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: flex-end;
        }
.timeline__document-entry__open {
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
        }
.timeline__document-entry__download {
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            margin-left: 1rem;
        }
.registration-help-checklist .fields {
		display: grid;
		gap: 1.5rem;
		grid-gap: 1.5rem;
		grid-gap: var(--spacing);
		gap: var(--spacing);
	}
.main {
	font-size: 1rem;
	font-size: var(--font-size-default);
	animation-duration: 0.4s;
	animation-duration: var(--sidebar-animation-duration);
	animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	animation-timing-function: var(--ease-in-out-quad);
}
.breakpoint-medium.sidebar-open .main {
		animation-name: main-sidebar-open;
	}
.breakpoint-medium.sidebar-closed .main {
		animation-name: main-sidebar-closed;
	}
.main > * {
		height: inherit;
		flex: 1;
		padding: 1.5rem;
		padding: var(--spacing);
	}
.sidebar-visible .main > * {
			padding: 1.5rem calc(1.5rem * 2);
			padding: var(--spacing) calc(var(--spacing) * 2);
		}
@keyframes main-sidebar-open {
	0% {
		transform: translateX(calc((max(20vw, 16.25rem) - 4rem) * -1));
		transform: translateX(calc((max(20vw, var(--sidebar-max-width)) - var(--sidebar-collapsed-width)) * -1));
	}

	100% {
		transform: translateX(0);
	}
}
@keyframes main-sidebar-closed {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(calc((max(20vw, 16.25rem) - 4rem) * -1));
		transform: translateX(calc((max(20vw, var(--sidebar-max-width)) - var(--sidebar-collapsed-width)) * -1));
	}
}
.dashboard {
	--grid-item-height: 250px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-row-gap: 1.5rem;
	grid-row-gap: var(--spacing);
	grid-column-gap: 1.5rem;
	grid-column-gap: var(--spacing);
	height: auto;
	flex-grow: 0;

	/* grid-template-rows: min-content min-content minmax(0, 100%); */
	/**
	 * max-height: 100% is needed to fix a webkit bug which occurs
	 * in combination with flex-grow and a parent flex container hosting
	 * a grid container
	 */
	/* max-height: 100%;

	&__dummy-link {
		width: 100vw;
		max-width: 30rem;

		&--disabled {
			opacity: 0.3;
		}

		& img {
			width: 100%;
		}
	} */

	/* &__items {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-gap: var(--spacing);
	} */
}
.dashboard--two-columns {
		grid-template-columns: 1fr 1fr;
	}
.dashboard__item {
		grid-column: span 1;
		min-height: var(--grid-item-height);
		background-color: rgb(27, 45, 57);
		background-color: var(--color-blue);
		padding: 1.5rem;
		padding: var(--spacing);
		position: relative;
		z-index: 1;
	}
.breakpoint-small .dashboard__item-header {
				flex-direction:column-reverse;
				align-items: flex-start;
			}
.breakpoint-medium .dashboard__item-header {
				justify-content: space-between;
				flex-direction:row;
			}
.dashboard__item:hover {
			cursor: pointer;
		}
.dashboard__item .background {
			z-index: -1;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
.dashboard__item .background--cover img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
.dashboard__item .background--cover::after {
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background: -webkit-gradient(linear, left bottom, left top, from(rgb(27, 45, 57)), to(transparent));
					background: linear-gradient(to top, rgb(27, 45, 57) 0%, transparent 100%);
					background: -webkit-gradient(linear, left bottom, left top, from(var(--color-blue)), to(transparent));
					background: linear-gradient(to top, var(--color-blue) 0%, transparent 100%);
				}
.dashboard__item--2 {
		grid-column: span 2;
	}
.change-address {
	--input-width: auto;
	position: relative;
}
.breakpoint-small .change-address .dashboard {
			grid-template-columns: 1fr;
		}
.breakpoint-medium .change-address .dashboard {
			grid-template-columns: 1fr 1fr;
		}
.change-address__input-fields-wrapper .input {
			margin-top: 0;
			/* margin-right: var(--spacing); */
		}
.change-address__input-fields-wrapper .icon {
			margin-right: 0.5rem;
			margin-right: var(--spacing--small);
		}
.change-address__fields {
		order: 0;
		display: grid;
		grid-template-columns: repeat(11, 1fr);
		gap: 1.5rem calc(1.5rem * 2);
		grid-gap: 1.5rem calc(1.5rem * 2);
		grid-gap: var(--spacing) calc(var(--spacing) * 2);
		gap: var(--spacing) calc(var(--spacing) * 2);
	}
.change-address__fields > * {
			grid-column: span 5;
		}
.change-address__fields .google-places-autocomplete {
			grid-column: span 8;
		}
.breakpoint-medium .change-address__fields .google-places-autocomplete {
				grid-column: span 8;
			}
.change-address__field.input--recipient {
			grid-column: span 8;
		}
.breakpoint-medium .change-address__field.input--recipient {
				grid-column: span 8;
			}
.change-address__field.input--house-number,
		.change-address__field.input--zip {
			grid-column: span 8;
			padding-left: 3.5rem;
		}
.breakpoint-medium .change-address__field.input--house-number, .breakpoint-medium .change-address__field.input--zip {
				padding-left: 0;
				grid-column: span 3;
			}
.change-address__field.input--country-code {
			grid-column: span 8;
			padding-left: 3.5rem;
		}
.breakpoint-medium .change-address__field.input--country-code {
				padding-left: 0;
				grid-column: span 3;
			}
.change-address__field.input--street,
		.change-address__field.input--city {
			grid-column: span 8;
		}
.breakpoint-medium .change-address__field.input--street, .breakpoint-medium .change-address__field.input--city {
				grid-column: span 8;
			}
.change-address__field.input--city {
			padding-left: 3.5rem;
		}
.change-address__field.input--care-of {
			/* icon width + icon padding of google-places-autocomplete field */
			grid-column: span 8;
			padding-left: 3.5rem;
		}
.breakpoint-medium .change-address__field.input--care-of {
				grid-column: span 5;
			}
.change-address__field.date-input--valid-from {
			grid-column: span 8;
			padding-left: 3.5rem;
		}
.breakpoint-medium .change-address__field.date-input--valid-from {
				padding-left: 0;
				grid-column: span 3;
			}
.change-address__submit {
		order: 3;
		margin-left: auto;
		margin-right: auto;
	}
.change-address__map {
		order: 2;
		width: 100%;
		height: 12.5rem;
		position: absolute;
		bottom: 0;
		/* view padding left */
		margin-left: calc(1.5rem * -1);
		margin-left: calc(var(--spacing) * -1);
		/* button margin top + half button height */
		margin-bottom: calc((1.5rem + 2rem) * -1);
		margin-bottom: calc((var(--spacing) + 2rem) * -1);
		pointer-events: none;
		width: 100vw;
	}
.breakpoint-medium .change-address__map {
			width: calc(100vw - max(20vw, 16.25rem));
			width: calc(100vw - max(20vw, var(--sidebar-max-width)));
		}
.change-address__map > img {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
.change-address__map * {
			cursor: default;
		}
.breakpoint-medium .sidebar-closed .change-address__map {
				width: calc(100vw - 4rem);
				width: calc(100vw - var(--sidebar-collapsed-width));
			}
.change-address__map.visually-hidden--false {
			position: relative;
		}
.change-address__error {
		order: 1;
		/* icon width + icon padding of google-places-autocomplete field */
		padding-left: 3.5rem;
		margin: 1.5rem 0;
		margin: var(--spacing) 0;
	}
.change-address__summary {
		display: grid;
		gap: 0.5rem 0;
		grid-gap: 0.5rem 0;
		grid-gap: var(--spacing--small) 0;
		gap: var(--spacing--small) 0;
	}
.breakpoint-medium .change-address__summary {
			grid-template-columns: auto 1fr;
			gap: 0 1.5rem;
			gap: 0 var(--spacing);
		}
.change-address__validate-address-modal {
		background-color: rgb(230, 230, 230);
		background-color: var(--color-grey--medium);
	}
.breakpoint-medium .change-address__validate-address-modal {
			background-color: transparent;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: repeat(4, auto);
			gap: 0 0.5rem;
			grid-gap: 0 0.5rem;
			grid-gap: 0 var(--spacing--small);
			gap: 0 var(--spacing--small);
			grid-auto-flow: column;
		}
.change-address__validate-address-modal > div {
			background-color: rgb(230, 230, 230);
			background-color: var(--color-grey--medium);
			padding: 0 1.5rem;
			padding: 0 var(--spacing);
		}
.change-address__validate-address-modal > div:first-child,
			.change-address__validate-address-modal > div:nth-child(5) {
				padding-top: 1.5rem;
				padding-top: var(--spacing);
			}
.change-address__validate-address-modal > div:last-child,
			.change-address__validate-address-modal > div:nth-child(4) {
				padding-bottom: 1.5rem;
				padding-bottom: var(--spacing);
			}
.change-bank-account {
	--input-width: auto;
}
.change-bank-account__input-fields-wrapper .input {
			margin-top: 0;
		}
.change-bank-account__input-fields-wrapper .icon {
			margin-right: 0.5rem;
			margin-right: var(--spacing--small);
		}
.change-bank-account__fields {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1.5rem calc(1.5rem * 2);
		grid-gap: 1.5rem calc(1.5rem * 2);
		grid-gap: var(--spacing) calc(var(--spacing) * 2);
		gap: var(--spacing) calc(var(--spacing) * 2);
	}
.change-bank-account__field {
		grid-column: 1 / 3;
		/* icon width + icon padding of google-places-autocomplete field */
		padding-left: 3.5rem;
	}
.breakpoint-medium .change-bank-account__field {
			grid-column: 1 / 2;
		}
.change-bank-account__field.input--account-holder,
		.change-bank-account__field.input--iban,
		.change-bank-account__field.input--account-number,
		.change-bank-account__field.input--bank-code,
		.change-bank-account__field.input--bic {
			padding-left: 0;
		}
.breakpoint-medium .change-bank-account__field.input--iban, .breakpoint-medium .change-bank-account__field.input--account-number {
				grid-row: 4;
			}
.change-bank-account__field.input--bank-code {
			padding-left: 3.5rem;
		}
.breakpoint-medium .change-bank-account__field.input--bank-code {
				padding-left: 0;
				grid-row: 4;
				grid-column: 2 / 3;
			}
.breakpoint-medium .change-bank-account__field.input--bank {
				grid-row: 5;
			}
.change-bank-account__field.input--bic {
			padding-left: 3.5rem;
		}
.breakpoint-medium .change-bank-account__field.input--bic {
				grid-column: 2 / 3;
				grid-row: 5;
				padding-left: 0;
			}
.change-bank-account__submit {
		margin-left: auto;
		margin-right: auto;
	}
.change-bank-account__summary {
		display: grid;
		gap: 0.5rem 0;
		grid-gap: 0.5rem 0;
		grid-gap: var(--spacing--small) 0;
		gap: var(--spacing--small) 0;
	}
.breakpoint-medium .change-bank-account__summary {
			grid-template-columns: auto 1fr;
			gap: 0 1.5rem;
			gap: 0 var(--spacing);
		}
.sectioned-form {
	--input-width: auto;
}
.sectioned-form__grid {
		display: grid;
		grid-template-columns: 0fr 1fr 1fr 0fr;
		gap: 0 1.5rem;
		grid-gap: 0 1.5rem;
		grid-gap: 0 var(--spacing);
		gap: 0 var(--spacing);
	}
.breakpoint-medium .sectioned-form__grid{
			grid-template-columns: 1.5rem 1fr 1fr 1.5rem;
			grid-template-columns: var(--spacing) 1fr 1fr var(--spacing);
		}
.sectioned-form__grid .horizontal-rule {
			margin: 1.5rem 0 calc(1.5rem / 2);
			margin: var(--spacing) 0 calc(var(--spacing) / 2);
			grid-column: 1 / -1;
		}
.sectioned-form__form,
	.sectioned-form__fields {
		display: contents;
	}
.sectioned-form__field {
		grid-column: 2 / 4;
		align-self: end;
	}
.sectioned-form__field:not(.hidden-within-flow),
		.sectioned-form__field:not(.input--hidden) {
			margin: calc(1.5rem / 2) 0;
			margin: calc(var(--spacing) / 2) 0;
		}
.sectioned-form__grid-item--center-column {
			grid-column: 2 / 4;
		}
.sectioned-form__grid-item--left-column {
			margin-right: 1rem;
			margin-right: var(--spacing--small-regular);
			grid-column: 2 / 3;
		}
.breakpoint-medium .sectioned-form__grid-item--left-column--m {
					grid-column: 2 / 3;
				}
.sectioned-form__grid-item--right-column {
			grid-column: 3 / 4;
			margin-left: 1rem;
			margin-left: var(--spacing--small-regular);
		}
.sectioned-form__grid-item--giant-headline {
			--giant-headline-text-indent: 0;
			margin-top: calc(1.5rem * 3);
			margin-top: calc(var(--spacing) * 3);
		}
.sectioned-form__submit {
		grid-column: 1 / -1;
		margin-left: auto;
		margin-right: auto;
	}
.sidebar {
	z-index: 10;
	box-shadow: 1px 0 1px rgba(0, 0, 0, 0.1), 2px 0 2px rgba(0, 0, 0, 0.1),
		4px 0 4px rgba(0, 0, 0, 0.1);
	box-shadow: var(--box-shadow-right--3-steps);
	background-color: rgb(27, 45, 57);
	background-color: var(--color-blue);
	margin-left: calc((max(20vw, 16.25rem)) * -1);
	margin-left: calc((max(20vw, var(--sidebar-max-width))) * -1);
	margin-left: 0;
	--scrollbar-background: var(--color-blue);
	position: fixed;
	top: 0;
	width: 100%;
	overscroll-behaviour: none;
}
.breakpoint-medium .sidebar {
		position: relative;
		width: max(20vw, 16.25rem);
		width: max(20vw, var(--sidebar-max-width));
	}
.sidebar__outer-wrapper {
		overflow: hidden;
		height: 100%;
		height: auto;
		max-height: 100vh;
	}
.sidebar__mobile-navigation-wrapper {
		grid-area: navigation;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex-direction: column;
		overflow: auto;
		height: calc(100vh - 6.25rem);
		height: calc(100vh - var(--sidebar-mobile-height));
	}
.sidebar-closed .sidebar__mobile-navigation-wrapper {
			display: none;
		}
.breakpoint-medium .sidebar__mobile-navigation-wrapper {
			display: contents;
		}
.sidebar__inner-wrapper {
		height: 100%;
		display: grid;
		grid-template-rows: auto auto 1fr auto;
		grid-template-columns: 1fr;
		grid-template-areas: 'logo navigation-toggle' 'navigation navigation';
	}
.breakpoint-medium .sidebar__inner-wrapper {
			grid-template-rows: auto auto auto auto auto minmax(0, 100%);
			grid-template-areas: 'logo' 'user' 'spacer' 'navigation-secondary' 'navigation-toggle' 'navigation';
			transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			transition: transform var(--sidebar-animation-duration) var(--ease-in-out-quad);
			height: 100vh;
		}
.breakpoint-medium.sidebar-closed .sidebar__inner-wrapper {
			transform: translateX(calc(100% - 4rem));
			transform: translateX(calc(100% - var(--sidebar-collapsed-width)));
			--sidebar-right-column-width: 0;
		}
.breakpoint-medium.sidebar-open .sidebar {
		animation-name: sidebar-open;
		animation-duration: 0.4s;
		animation-duration: var(--sidebar-animation-duration);
		animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
		animation-timing-function: var(--ease-in-out-quad);
	}
.breakpoint-medium.sidebar-closed .sidebar {
		margin-left: calc((max(20vw, 16.25rem) - 4rem) * -1);
		margin-left: calc((max(20vw, var(--sidebar-max-width)) - var(--sidebar-collapsed-width)) * -1);
		animation-name: sidebar-closed;
		animation-duration: 0.4s;
		animation-duration: var(--sidebar-animation-duration);
		animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
		animation-timing-function: var(--ease-in-out-quad);
	}
.sidebar__user,
	.sidebar__logo,
	.sidebar__spacer,
	.sidebar .version-number {
		transition: opacity 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: opacity var(--sidebar-animation-duration) var(--ease-in-out-quad);
	}
.breakpoint-medium.sidebar-closed .sidebar__user, .breakpoint-medium.sidebar-closed .sidebar__logo, .breakpoint-medium.sidebar-closed .sidebar__spacer, .breakpoint-medium.sidebar-closed .sidebar .version-number {
			opacity: 0;
		}
.sidebar__logo {
		grid-area: logo;
		height: 6.25rem;
	}
.sidebar__user {
		grid-area: user;
		justify-self: flex-end;
		margin-top: auto;
	}
.breakpoint-medium .sidebar__user {
			justify-self: flex-start;
		}
.sidebar__spacer {
		display: grid;
		align-content: end;
		flex: 1;
		grid-area: spacer;
		align-self: end;
		justify-self: flex-end;
		width: 100%;
		opacity: 0.1;
		--hr-line-height: 1px;
	}
.sidebar__navigation-toggle {
		align-self: center;
		grid-area: navigation-toggle;
		transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: transform var(--sidebar-animation-duration) var(--ease-in-out-quad);
		margin-right: 1.5rem;
		margin-right: var(--spacing);
	}
.breakpoint-medium .sidebar__navigation-toggle {
			margin-right: inherit;
			width: 4rem;
			width: var(--sidebar-collapsed-width);
		}
.sidebar__navigation {
		grid-area: navigation;
		align-self: end;

		/* height: 500px; */
		max-height: 100%;
		width: 100%;
	}
.breakpoint-medium .sidebar__navigation {
			justify-self: stretch;
			overflow: auto;
		}
.sidebar__secondary-navigation {
		grid-area: navigation-secondary;
		align-self: end;
		width: 100%;
		/* height: 500px; */
		max-height: 100%;
	}
.breakpoint-medium .sidebar__secondary-navigation {
			overflow: auto;
			justify-self: stretch;
		}
.sidebar .version-number {
		justify-content: flex-end;
		margin-top: auto;
		margin-right: 1.4rem;
	}
.breakpoint-medium .sidebar .version-number {
			margin-right: 0;
			justify-self: end;
		}
@keyframes sidebar-open {
	0% {
		transform: translateX(calc((max(20vw, 16.25rem) - 4rem) * -1));
		transform: translateX(calc((max(20vw, var(--sidebar-max-width)) - var(--sidebar-collapsed-width)) * -1));
	}

	100% {
		transform: translateX(0);
	}
}
@keyframes sidebar-closed {
	0% {
		transform: translateX(0);
		margin-left: 0;
	}

	100% {
		transform: translateX(calc((max(20vw, 16.25rem) - 4rem) * -1));
		transform: translateX(calc((max(20vw, var(--sidebar-max-width)) - var(--sidebar-collapsed-width)) * -1));
		margin-left: 0;
	}
}
.navigation-toggle__label {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-content: center;
		justify-content: center;
		place-content: center;
		padding: 1.5rem 0;
		padding: var(--spacing) 0;
		width: 100%;
		cursor: pointer;

		--polyline-color: var(--color-grey);
	}
.navigation-toggle__label:hover {
			--polyline-color: var(--color-white);
		}
.navigation-toggle__text {
		border-top: 1px solid rgb(180, 150, 83);
		border-top: 1px solid var(--color-gold);
		border-bottom: 1px solid rgb(180, 150, 83);
		border-bottom: 1px solid var(--color-gold);
		height: 2.2rem;
		margin-top: 2px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
	}
.breakpoint-medium .navigation-toggle__text {
			display: none;
		}
.navigation-toggle__icon {
		display: none;
		transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: transform var(--sidebar-animation-duration) var(--ease-in-out-quad);
		--svg-color: var(--polyline-color);
	}
.breakpoint-medium .navigation-toggle__icon {
			display: block;
		}
.navigation-toggle__icon polyline {
			transition: stroke 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
			transition: stroke 0.25s var(--ease-in-out-quad);
			stroke-width: 1;
		}
.navigation-toggle__checkbox {
		display: none;
	}
.logo {
	height: 6.25rem;
	display: grid;
	grid-template-columns: -webkit-max-content 1fr;
	grid-template-columns: max-content 1fr;
	padding: 0.5rem 1.5rem 0.5rem 0.5rem;
	padding: var(--spacing--small) var(--spacing) var(--spacing--small) var(--spacing--small);
}
.logo__stripes {
		display: grid;
		grid-template-rows: 1fr 1fr;
		align-self: center;
	}
.logo--only-stripes .logo__stripes {
			padding-left: 1rem;
			padding-left: var(--spacing--small-regular);
			grid-row: 1/2;
			grid-column: 1/-1;
		}
.logo__stripes hr {
			width: 100%;
			opacity: 0.1;
			--hr-line-height: 1px;
		}
.sidebar-navigation {
	--border-color: var(--color-grey);
	z-index: 300;
}
.sidebar-navigation__item {
		background-color: transparent;
		background-color: var(--sidebar-navigation-active-color, transparent);
		transition: background-color 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: background-color 0.25s var(--ease-in-out-quad);
	}
.sidebar-navigation__item:hover {
			--sidebar-navigation-active-color: var(--color-blue--dark);
		}
.sidebar-navigation__item--active {
			--sidebar-navigation-active-color: var(--color-blue--medium);
			position: sticky;
			top: 0;
			bottom: 0;
		}
.sidebar-navigation__item:first-child {
			--border-color: transparent;
		}
.sidebar-navigation__item--imprint {
			font-size: 0.75rem;
			font-size: var(--font-size-s);
			--border-color: transparent;
		}
.sidebar-navigation__item--imprint .sidebar-navigation__link {
				justify-content: flex-end;
			}
.sidebar-navigation__item--imprint .sidebar-navigation__link-text {
				flex: initial;
				padding-top: 1.5rem;
				padding-top: var(--spacing);
				padding-bottom: 1.5rem;
				padding-bottom: var(--spacing);
			}
.sidebar-navigation__link {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		-webkit-text-decoration: none;
		text-decoration: none;
	}
.sidebar-navigation__icon {
		flex-shrink: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding: 0.5rem;
		padding: var(--spacing--small);
	}
.sidebar-navigation__icon svg {
			box-sizing: content-box;
			margin: 0 auto;
			padding: 1.5rem;
			padding: var(--spacing);
		}
.sidebar-navigation__node-icon {
		width: 64px;
		height: 64px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		align-items: center;
	}
.sidebar-navigation__flag-icon {
		width: 65px;
		height: 64px;
	}
.sidebar-navigation__link-text {
		align-self: stretch;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex: 1;
		align-items: center;
		margin-left: 0.5rem;
		margin-left: var(--spacing--small);
		padding-right: 1.5rem;
		padding-right: var(--spacing);
		padding-bottom: 1px;
		border-top: 1px solid var(--border-color);
	}
.language-menu {
	--border-color: var(--color-grey);
	z-index: 300;
}
.language-menu__item {
		cursor: pointer;
		background-color: transparent;
		background-color: var(--sidebar-navigation-active-color, transparent);
		transition: background-color 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: background-color 0.25s var(--ease-in-out-quad);
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		max-width: 350px;
	}
.language-menu__item:hover {
			--sidebar-navigation-active-color: var(--color-blue--dark);
		}
.language-menu__item--active {
			--sidebar-navigation-active-color: var(--color-blue);
			position: sticky;
			top: 0;
			bottom: 0;
		}
.language-menu__item:first-child {
			--border-color: transparent;
		}
.language-menu__item--imprint {
			font-size: 0.75rem;
			font-size: var(--font-size-s);
			--border-color: transparent;
		}
.language-menu__item--imprint .language-menu__link {
				justify-content: flex-end;
			}
.language-menu__item--imprint .language-menu__link-text {
				flex: initial;
				padding-top: 1.5rem;
				padding-top: var(--spacing);
				padding-bottom: 1.5rem;
				padding-bottom: var(--spacing);
			}
.language-menu__link {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		-webkit-text-decoration: none;
		text-decoration: none;
	}
.language-menu__icon {
		flex-shrink: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding: 0.5rem;
		padding: var(--spacing--small);
	}
.language-menu__icon svg {
			box-sizing: content-box;
			margin: 0 auto;
			padding: 1.5rem;
			padding: var(--spacing);
		}
.language-menu__node-icon {
		width: 64px;
		height: 64px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		align-items: center;
	}
.language-menu__flag-icon {
		width: 65px;
		height: 64px;
	}
.language-menu__link-text {
		align-self: stretch;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex: 1;
		align-items: center;
		margin-left: 0.5rem;
		margin-left: var(--spacing--small);
		padding-right: 1.5rem;
		padding-right: var(--spacing);
		padding-bottom: 1px;
		border-top: 1px solid var(--border-color);
	}
.profile {
	padding: 0;
	display: grid;
}
.breakpoint-medium .profile {
		grid-template-columns: -webkit-max-content auto;
		grid-template-columns: max-content auto;
		grid-template-rows: 1fr -webkit-max-content;
		grid-template-rows: 1fr max-content;
	}
.profile__navigation {
		width: max(20vw, 16.25rem);
		width: max(20vw, var(--sidebar-max-width));
		background: rgb(16, 32, 42);
		background: var(--color-blue--medium);
		display: none;
	}
.breakpoint-medium .profile__navigation {
			display: block;
		}
.profile__navigation .sidebar-navigation__link-text {
			border-top: 1px solid rgb(127, 127, 127);
			border-top: 1px solid var(--color-grey);
		}
.profile__navigation .sidebar-navigation__item:first-child .sidebar-navigation__link-text {
				border-top: none;
			}
.profile__navigation .sidebar-navigation__item--active {
			background-color: rgb(47, 76, 96);
			background-color: var(--color-blue--light);
		}
.profile--unregistered .profile__navigation {
			grid-row: 1/3;
		}
.profile__main {
		background: rgb(47, 76, 96);
		background: var(--color-blue--light);
		min-height: calc(100vh - 6.25rem);
		min-height: calc(100vh - var(--sidebar-mobile-height));
		max-width: 100vw;
	}
.breakpoint-medium .profile__main {
			min-height: 100vh;
		}
.profile .form .fields > * + * {
			margin-top: 1.5rem;
			margin-top: var(--spacing);
		}
.profile .registration__hint {
		grid-column: 2/3;
	}
.profile__navigation {
	height: 100vh;
	position: sticky;
	top: 0;
	overflow: auto;
}
/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
	-webkit-user-select: none;
	        user-select: none;
}
/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}
html {
	font-size: 16px;
}
/* Set core body defaults */
body {
	/* min-height: 100vh;
	scroll-behavior: smooth; */
}
/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
	list-style: none;
}
/* A elements that don't have a class get default styles */
a:not([class]) {
	-webkit-text-decoration-skip: ink;
	        text-decoration-skip-ink: auto;
}
/* Make images easier to work with */
img {
	max-width: 100%;
	height: auto;
	display: block;
}
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}
input,
textarea {
	-webkit-user-select: auto;
	        user-select: auto;
}
:root {

	--sidebar-mobile-height: 6.25rem;

	/* fonts */
	--font-family-light: 'PxGroteskLight';
	--font-family-regular: 'PxGroteskRegular';
	--font-family-bold: 'PxGroteskBold';

	/* font-sizes */
	--font-size-giant: 3.75rem; /* 60px */
	--font-size-xxl: 2.625rem; /* 42px */
	--font-size-xl: 1.625rem; /* 26px */
	--font-size-l: 1.3125rem; /* 21px */
	--font-size-m: 1.125rem; /* 18px */
	--font-size-default: 1rem; /* 16px */
	--font-size-s: 0.75rem; /* 12px */
	--font-size-xs: 0.5rem; /* 8px */

	/* element-sizes */
	--input-width: 22.5rem;

	/* colors */

	--blue: 27, 45, 57;
	--blue--light: 47, 76, 96;
	--blue--medium: 16, 32, 42;
	--blue--dark: 11, 19, 25;

	--blue-shade-1: 47, 63, 74;
	--blue-shade-2: 70, 85, 94;
	--blue-shade-3: 99, 111, 119;
	--gold: 180, 150, 83;
	--white: 255, 255, 255;
	--black: 0, 0, 0;
	--grey: 127, 127, 127;
	--grey--medium: 230, 230, 230;
	--grey--light: 253, 253, 253;
	--teal: 1, 254, 161;
	--orange: 255, 168, 0;
	--green: 61, 223, 0;
	--red: 203, 0, 0;
	--red--medium: 255, 69, 0;
	--black: 0, 0, 0;

	--color-blue: rgb(var(--blue));
	--color-blue--light: rgb(var(--blue--light));
	--color-blue--medium: rgb(var(--blue--medium));
	--color-blue--dark: rgb(var(--blue--dark));
	--color-gold: rgb(var(--gold));
	--color-gold--faded: rgb(var(--gold), 0.15);
	--color-white: rgb(var(--white));
	--color-black: rgb(var(--black));
	--color-grey: rgb(var(--grey));
	--color-grey--medium: rgb(var(--grey--medium));
	--color-grey--light: rgb(var(--grey--light));
	--color-orange: rgb(var(--orange));
	--color-green: rgb(var(--green));
	--color-red: rgb(var(--red));
	--color-red--medium: rgb(var(--red--medium));
	--color-teal: rgba(var(--teal));
	--color-black--transparent: rgba(var(--black), 0.666);
	--color-white--transparent: rgba(var(--white), 0.05);
	--color-blue--transparent: rgba(var(--blue), 0.13);

	--text-color: var(--color-grey--light);
	--error-color: var(--color-red);

	--svg-color: var(--color-white);

	/* inputs */
	--input-primary-color: var(--white);
	--input-secondary-color: var(--gold);
	--input-tertiary-color: var(--grey);

	/* hr */
	--hr-line-color: var(--color-white);
	--hr-line-height: 0.125rem;

	/* miscellaneous */
	--spacing--large: 3rem;
	--spacing: 1.5rem;
	--spacing--small-regular: 1rem;
	--spacing--small: 0.5rem;
	--spacing--very-small: 0.25rem;
	--border-radius: 0.5rem;
	--margin: var(--spacing);
	--padding: var(--spacing);

	/* line height */
	--line-height-spaced: 1.333;

	--sidebar-max-width: 16.25rem;
	--sidebar-collapsed-width: 4rem;
	--sidebar-animation-duration: 0.4s;

	/* --sidebar-left-column-width: 7.8125rem; */
	/* --sidebar-right-column-width: 14.6875rem; */

	--border-radius--small: 0.2rem;
	--border-radius--round: 999rem;
	--title-icon-dimension: 4.375rem;

	/* charts */
	--tooltip-background-color: var(--color-grey);

	--pan-button-color: var(--color-white);
	--pension-assets-ghost-bars-color: var(--color-white);
	--pension-assets-chart-grid-color: var(--color-grey);

	/* snap-slider */
	--snap-slider-button-color: var(--color-gold);

	/* accordion */
	--accordion-toggle-color: var(--color-white);

	/* error-message */
	--error-message-icon-color: var(--color-red);

	/* transitions */
	--transition-duration: 0.5s;
	--ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	--ease-out-back-less: cubic-bezier(0.175, 0.885, 0.32, 1.05);
	--ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	--ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
	--ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	--ease-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

	--transition: var(--transition-duration);
	--input-transition: 0.25s var(--ease-in-out-quad);

	/* shadows */
	--box-shadow-bottom--3-steps: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1);
	--box-shadow-bottom--4-steps: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1);
	--box-shadow-bottom--5-steps: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);

	--box-shadow-right--3-steps: 1px 0 1px rgba(0, 0, 0, 0.1), 2px 0 2px rgba(0, 0, 0, 0.1),
		4px 0 4px rgba(0, 0, 0, 0.1);

	--box-shadow-right--5-steps: 1px 0 1px rgba(0, 0, 0, 0.1), 2px 0 2px rgba(0, 0, 0, 0.1),
		4px 0 4px rgba(0, 0, 0, 0.1), 8px 0 8px rgba(0, 0, 0, 0.1), 16px 0 16px rgba(0, 0, 0, 0.1);
}
@keyframes fadeInDown {
		0% {
			opacity: 0;
			transition: transformy(calc(3rem * -2));
			transition: transformy(calc(var(--spacing--large) * -2));
		}

		100% {
			opacity: 0;
			transition: transformy(0);
		}
	}
:root {

	--scrollbar-foreground: var(--color-grey);
	--scrollbar-background: var(--color-blue--medium);
}
@font-face {
	font-family: 'PxGroteskLight';
	src: url('fonts/Px-Grotesk-Light.woff2') format('woff2'), url('fonts/Px-Grotesk-Light.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'PxGroteskRegular';
	src: url('fonts/Px-Grotesk-Regular.woff2') format('woff2'), url('fonts/Px-Grotesk-Regular.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'PxGroteskBold';
	src: url('fonts/Px-Grotesk-Bold.woff2') format('woff2'), url('fonts/Px-Grotesk-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
* {
	font-family: 'PxGroteskLight';
	font-family: var(--font-family-overwrite, var(--font-family-light));
	font-weight: normal;
}
b,
strong,
.bold {
	font-family: 'PxGroteskBold';
	font-family: var(--font-family-bold);
	--font-family-overwrite: var(--font-family-bold);
	/* letter-spacing: 0.0625rem; */
}
.spaced-letters {
	letter-spacing: 0.0625rem;
}
h1 {
	margin: 1em 0;
	font-family: 'PxGroteskBold';
	font-family: var(--font-family-bold);
}
h2 {
	margin: 1em 0;
	font-family: 'PxGroteskBold';
	font-family: var(--font-family-bold);
}
h3 {
	margin: 1em 0;
	font-family: 'PxGroteskBold';
	font-family: var(--font-family-bold);
}
h4 {
	margin: 1em 0;
	font-family: 'PxGroteskBold';
	font-family: var(--font-family-bold);
}
h5 {
	margin: 1em 0;
	font-family: 'PxGroteskBold';
	font-family: var(--font-family-bold);
}
h1 {
	font-size: 1.625rem;
	font-size: var(--font-size-xl);
}
h2 {
	font-size: 1.3125rem;
	font-size: var(--font-size-l);
}
.breakpoint-medium h2 {
		font-size: 1.625rem;
		font-size: var(--font-size-xl);
	}
a {
	color: currentColor;
}
p {
	margin: 1.5rem 0;
	margin: var(--spacing) 0;
}
p + p {
		margin-top: 0;
	}
ul {
	padding-left: 1em;
}
ul:has(li) {
		margin-top: 0.5em;
	}
ul li:not(:last-child) {
			margin-bottom: 0.5em;
		}
ul:not(.no-marker) li::marker {
			content: '– ';
		}
mark {
	background-color: transparent;
	color: rgb(180, 150, 83);
	color: var(--color-gold);
}
.pre-line-break {
	white-space: pre-line;
}
.character-spacing--before::before {
			content: '\00a0';
		}
.character-spacing--after::after {
			content: '\00a0';
		}
.giant-headline {
	font-family: 'PxGroteskLight';
	font-family: var(--font-family-light);
	letter-spacing: 0;
	line-height: 1;
	font-size: 1.625rem;
	font-size: var(--font-size-xl);
	margin: 0.25em 0;
}
.breakpoint-medium .giant-headline {
		font-size: 3.75rem;
		font-size: var(--font-size-giant);
	}
.bold-small-heading {
	letter-spacing: 0.0625rem;
	color: rgb(180, 150, 83);
	color: var(--color-gold);
	font-size: 0.75rem;
	font-size: var(--font-size-s);
	font-family: 'PxGroteskBold';
	font-family: var(--font-family-bold);
	text-transform: uppercase;
}
.text-align--left {
			text-align: left;
		}
.text-align--center {
			text-align: center;
		}
.text-align--right {
			text-align: right;
		}
.text-color-gold {
		color: rgb(180, 150, 83);
		color: var(--color-gold);
	}
.text-color-white {
		color: rgb(255, 255, 255);
		color: var(--color-white);
	}
.text-color-red {
		color: rgb(203, 0, 0);
		color: var(--color-red);
	}
.text-color-blue {
		color: rgb(27, 45, 57);
		color: var(--color-blue);
	}
.text-color-blue--light {
		color: rgb(47, 76, 96);
		color: var(--color-blue--light);
	}
.text-color-grey--light {
		color: rgb(253, 253, 253);
		color: var(--color-grey--light);
	}
.text-color-grey {
		color: rgb(127, 127, 127);
		color: var(--color-grey);
	}
.text-color-orange {
		color: rgb(255, 168, 0);
		color: var(--color-orange);
	}
.text-color-teal {
		color: rgba(1, 254, 161);
		color: var(--color-teal);
	}
.font-family-light {
		font-family: 'PxGroteskLight';
		font-family: var(--font-family-light);
	}
.font-family-regular {
		font-family: 'PxGroteskRegular';
		font-family: var(--font-family-regular);
	}
.font-family-bold {
		font-family: 'PxGroteskBold';
		font-family: var(--font-family-bold);
	}
.font-size-xxl {
		font-size: 2.625rem;
		font-size: var(--font-size-xxl);
	}
.font-size-xl {
		font-size: 1.625rem;
		font-size: var(--font-size-xl);
	}
.font-size-l {
		font-size: 1.3125rem;
		font-size: var(--font-size-l);
	}
.font-size-m {
		font-size: 1.125rem;
		font-size: var(--font-size-m);
	}
.font-size-default {
		font-size: 1rem;
		font-size: var(--font-size-default);
	}
.font-size-s {
		font-size: 0.75rem;
		font-size: var(--font-size-s);
	}
.font-size-xs {
		font-size: 0.5rem;
		font-size: var(--font-size-xs);
	}
.text-transform-uppercase {
		text-transform: uppercase;
	}
.text-transform-lowercase {
		text-transform: lowercase;
	}
hr {
	background-color: rgb(255, 255, 255);
	background-color: var(--hr-line-color);
	border: none;
	height: 0.125rem;
	height: var(--hr-line-height);
	margin: 0.5rem auto;
}
hr.hr--narrow {
			width: 15vw;
		}
hr.hr--thick {
			height: 0.25rem;
		}
hr.hr--gold {
			--hr-line-color: var(--color-gold);
		}
html {
	overflow-x: hidden;
	overscroll-behavior: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-hyphens: auto;
	        hyphens: auto;
	word-break: break-word;
	background-color: rgb(253, 253, 253);
	background-color: var(--color-grey--light);
}
html.sidebar-open {
		overflow: hidden;
	}
html.breakpoint-medium.sidebar-open {
		overflow: auto;
	}
html[data-ui-background-color='dark'] {
		background-color: rgb(11, 19, 25);
		background-color: var(--color-blue--dark);
	}
html[data-ui-background-color='light'] {
		background-color: rgb(27, 45, 57);
		background-color: var(--color-blue);
	}
html {
	scrollbar-width: thin;
	scrollbar-color: rgb(127, 127, 127) rgb(16, 32, 42);
	scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
::-webkit-scrollbar {
	width: 10px; /* Mostly for vertical scrollbars */
	height: 10px; /* Mostly for horizontal scrollbars */
}
::-webkit-scrollbar-thumb {
	/* Foreground */
	background: rgb(127, 127, 127);
	background: var(--scrollbar-foreground);
	border: 3px solid rgb(16, 32, 42);
	border: 3px solid var(--scrollbar-background);
	border-radius: 999em;
}
::-webkit-scrollbar-track {
	/* Background */
	border-radius: 999em;
	background: rgb(16, 32, 42);
	background: var(--scrollbar-background);
}
body {
	line-height: 1.333;
	line-height: var(--line-height-spaced);
	font-size: 1rem;
	font-size: var(--font-size-default);
}
@supports (padding: env(safe-area-inset-bottom)) {
body {
		padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)
}
	}
body * {
		-webkit-user-select: auto;
		        user-select: auto;
		-webkit-user-select: var(--user-select, auto);
		        user-select: var(--user-select, auto);
	}
#root {
	color: rgb(253, 253, 253);
	color: var(--text-color);
	background-color: rgb(16, 32, 42);
	background-color: var(--color-blue--medium);
	padding-top: 6.25rem;
	padding-top: var(--sidebar-mobile-height);
}
.breakpoint-medium #root {
		padding-top: 0;
		height: 100vh;
		max-height: 100vh;
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: minmax(0, 100%) auto;
		grid-template-areas: 'sidebar main' 'footer footer';
	}
#root > sidebar {
		grid-area: sidebar;
	}
#root > main {
		min-height: calc(100vh - 6.25rem);
		min-height: calc(100vh - var(--sidebar-mobile-height));
		grid-area: main;
	}
.breakpoint-medium #root > main {
			overflow-x: hidden;
		}
#root > footer {
		grid-area: footer;
	}
#root > dialog {
		grid-column: 1 / -1;
		grid-row: 1 / -1;
	}
.hidden {
	display: none !important;
}
.breakpoint-medium .hidden-medium {
			display: none !important;
		}
html:not(.breakpoint-medium) .hidden-medium--lt {
			display: none !important;
		}
.hidden-within-flow {
	visibility: hidden !important;
	margin: 0 !important;
	width: 0 !important;
}
.hidden-within-flow {
	height: 0;
	overflow: hidden;
	pointer-events: none;
	z-index: -1;
}
.visually-hidden {
	opacity: 0;
	transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: opacity 0.25s var(--ease-in-out-quad);
}
.visually-hidden--false {
		opacity: 1;
	}
.fragment {
	display: contents;
}
.fit-content-width {
	max-width: -moz-fit-content;
	max-width: -webkit-fit-content;
	max-width: fit-content;
}
.min-content-width {
	max-width: -webkit-min-content;
	max-width: -moz-min-content;
	max-width: min-content;
}
.medium-content-width {
	max-width: 45rem;
}
.max-content-width {
	max-width: 60rem;
}
.horizontal-center {
	margin-left: auto;
	margin-right: auto;
}
.data-grid {
	display: grid;
	grid-template-columns: -webkit-max-content auto;
	grid-template-columns: max-content auto;
	grid-column-gap: 1.5rem;
	grid-column-gap: var(--spacing);
}
.default-view {
	max-width: 60rem;
}
.breakpoint-medium .default-view {
		margin: calc(1.5rem * 2) 0 calc(1.5rem * 2) calc(1.5rem * 2);
		margin: calc(var(--spacing) * 2) 0 calc(var(--spacing) * 2) calc(var(--spacing) * 2);
	}
/* css attribute is set in basic.css */
.user-select {
	--user-select: auto;
}
.user-select--text {
		--user-select: text;
	}
.user-select--none {
		--user-select: none;
	}
.user-select--none-force * {
				--user-select: none;
			}
.user-select--all {
		--user-select: all;
	}
.pointer-events--none {
		pointer-events: none;
	}
.pointer-events--force-none {
		pointer-events: none !important;
	}
.pointer-events--force-none * {
			pointer-events: none !important;
		}
.no-margin {
	margin: 0 !important;
}
.no-margin--top {
		margin-top: 0 !important;
	}
.no-margin--top > *:first-child {
			margin-top: 0 !important;
		}
.no-margin--bottom {
		margin-bottom: 0 !important;
	}
.no-margin--bottom > *:first-child {
			margin-bottom: 0 !important;
		}
.no-margin--left {
		margin-left: 0 !important;
	}
.no-margin--left > *:first-child {
			margin-left: 0 !important;
		}
.no-margin--right {
		margin-right: 0 !important;
	}
.no-margin--right > *:first-child {
			margin-right: 0 !important;
		}
.border-radius--none {
		border-radius: 0;
	}
.border-radius--none::before,
		.border-radius--none::after {
			border-radius: 0;
		}
.deactivated {
	opacity: 0.33;
	pointer-events: none;
}
.cursor--pointer {
		cursor: pointer;
	}
.hover--cursor-pointer:hover {
		cursor: pointer;
	}
.margin {
	margin: 1.5rem;
	margin: var(--margin);
}
.margin--right {
		margin-right: 1.5rem;
		margin-right: var(--margin);
	}
.margin--left {
		margin-left: 1.5rem;
		margin-left: var(--margin);
	}
.breakpoint-medium .margin--left--m {
				margin-left: 1.5rem;
				margin-left: var(--margin);
			}
.margin--bottom {
		margin-bottom: 1.5rem;
		margin-bottom: var(--margin);
	}
.margin--top {
		margin-top: 1.5rem;
		margin-top: var(--margin);
	}
.breakpoint-medium .margin--top--m {
				margin-top: 1.5rem;
				margin-top: var(--margin);
			}
.margin--vertical {
		margin-top: 1.5rem;
		margin-top: var(--margin);
		margin-bottom: 1.5rem;
		margin-bottom: var(--margin);
	}
.margin--horizontal {
		margin-left: 1.5rem;
		margin-left: var(--margin);
		margin-right: 1.5rem;
		margin-right: var(--margin);
	}
.margin--center {
		margin-left: auto;
		margin-right: auto;
	}
.margin--small {
		--margin: var(--spacing--small);
	}
.margin--small-negative {
			--margin: calc(var(--spacing--small) * -1);
		}
.margin--medium {
		--margin: var(--spacing--small-regular);
	}
.margin--medium-negative {
			--margin: calc(var(--spacing--small-regular) * -1);
		}
.margin--default {
		--margin: var(--spacing);
	}
.margin--default-negative {
			--margin: calc(var(--spacing) * -1);
		}
.margin--large {
		--margin: calc(var(--spacing) * 2);
	}
.margin--large-negative {
			--margin: calc(var(--spacing-spacing) * -2);
		}
.margin--extra-large {
		--margin: calc(var(--spacing) * 3);
	}
.margin--extra-large-negative {
			--margin: calc(var(--spacing) * -3);
		}
.margin--negative {
		--margin: calc(var(--spacing) * -1);
	}
.padding {
	padding: 1.5rem;
	padding: var(--padding);
}
.padding--vertical {
		padding-top: 1.5rem;
		padding-top: var(--padding);
		padding-bottom: 1.5rem;
		padding-bottom: var(--padding);
	}
.padding--left {
		padding-left: 1.5rem;
		padding-left: var(--padding);
	}
.padding--right {
		padding-right: 1.5rem;
		padding-right: var(--padding);
	}
.padding--top {
		padding-top: 1.5rem;
		padding-top: var(--padding);
	}
.padding--bottom {
		padding-bottom: 1.5rem;
		padding-bottom: var(--padding);
	}
.padding--horizontal {
		padding-left: 1.5rem;
		padding-left: var(--padding);
		padding-right: 1.5rem;
		padding-right: var(--padding);
	}
.padding--default {
		--padding: var(--spacing);
	}
.padding--default-negative {
			--padding: calc(var(--spacing) * -1);
		}
.padding--small {
		--padding: var(--spacing--small);
	}
.padding--small-negative {
			--padding: calc(var(--spacing--small) * -1);
		}
.padding--large {
		--padding: calc(var(--spacing) * 2);
	}
.padding--large-negative {
			--padding: calc(var(--spacing--small) * -2);
		}
.padding--extra-large {
		--padding: calc(var(--spacing) * 3);
	}
.padding--extra-large-negative {
			--padding: calc(var(--spacing--small) * -3);
		}
.no-padding {
	padding: 0 !important;
}
.no-padding > *:first-child {
		padding: 0 !important;
	}
.no-padding--top {
		padding-top: 0 !important;
	}
.no-padding--top > *:first-child {
			padding-top: 0 !important;
		}
.no-padding--bottom {
		padding-bottom: 0 !important;
	}
.no-padding--bottom > *:first-child {
			padding-bottom: 0 !important;
		}
.no-padding--left {
		padding-left: 0 !important;
	}
.no-padding--left > *:first-child {
			padding-left: 0 !important;
		}
.no-padding--right {
		padding-right: 0 !important;
	}
.no-padding--right > *:first-child {
			padding-right: 0 !important;
		}
.gap {
	--gap: var(--spacing);
	gap: 1.5rem 1.5rem;
	gap: var(--block-gap, var(--gap)) var(--inline-gap, var(--gap));
}
.gap--vertical {
		--inline-gap: 0;
	}
.gap--horizontal {
		--block-gap: 0;
	}
.gap--default {
		--gap: var(--spacing);
	}
.gap--small {
		--gap: var(--spacing--small);
	}
.gap--large {
		--gap: calc(var(--spacing) * 2);
	}
.gap--extra-large {
		--gap: calc(var(--spacing) * 3);
	}
.opacity--0 {
		opacity: calc(0 / 10);
	}
.opacity--1 {
		opacity: calc(1 / 10);
	}
.opacity--2 {
		opacity: calc(2 / 10);
	}
.opacity--3 {
		opacity: calc(3 / 10);
	}
.opacity--4 {
		opacity: calc(4 / 10);
	}
.opacity--5 {
		opacity: calc(5 / 10);
	}
.opacity--6 {
		opacity: calc(6 / 10);
	}
.opacity--7 {
		opacity: calc(7 / 10);
	}
.opacity--8 {
		opacity: calc(8 / 10);
	}
.opacity--9 {
		opacity: calc(9 / 10);
	}
.opacity--10 {
		opacity: calc(10 / 10);
	}
.background-color--white {
		background-color: rgb(255, 255, 255);
		background-color: var(--color-white);
	}
.background-color--blue {
		background-color: rgb(27, 45, 57);
		background-color: var(--color-blue);
	}
.background-color--blue--light {
		background-color: rgb(47, 76, 96);
		background-color: var(--color-blue--light);
	}
.background-color--grey--light {
		background-color: rgb(253, 253, 253);
		background-color: var(--color-grey--light);
	}
.background-color--grey--medium {
		background-color: rgb(230, 230, 230);
		background-color: var(--color-grey--medium);
	}
.background-color--teal {
		background-color: rgba(1, 254, 161);
		background-color: var(--color-teal);
	}
.background-color--green {
		background-color: rgb(61, 223, 0);
		background-color: var(--color-green);
	}
.flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.flex--inline {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
.breakpoint-medium .flex--m {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
		}
.breakpoint-large .flex--l {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
		}
.flex--direction-column {
		flex-direction: column;
	}
.flex--direction-column-reverse {
			flex-direction: column-reverse;
		}
html:not(.breakpoint-medium) .flex--direction-column--m-lt {
				flex-direction: column;
			}
.flex--direction-row {
		flex-direction: row;
	}
.flex--direction-row-reverse {
			flex-direction: row-reverse;
		}
html:not(.breakpoint-medium) .flex--direction-row--m-lt {
				flex-direction: row;
			}
.flex--wrap {
		flex-wrap: wrap;
	}
.flex--justify-content-start {
			justify-content: flex-start;
		}
.flex--justify-content-end {
			justify-content: flex-end;
		}
.flex--justify-content-center {
			justify-content: center;
		}
.flex--justify-content-between {
			justify-content: space-between;
		}
.flex--justify-content-around {
			justify-content: space-around;
		}
.flex--justify-content-evenly {
			justify-content: space-around; /* fallback for older browsers */
			justify-content: space-evenly;
		}
.flex--align-items-start {
			align-items: flex-start;
		}
.flex--align-items-end {
			align-items: flex-end;
		}
.flex--align-items-center {
			align-items: center;
		}
.flex--align-items-stretch {
			align-items: stretch;
		}
.flex--align-content-start {
			align-content: flex-start;
		}
.flex--align-content-end {
			align-content: flex-end;
		}
.flex--align-content-center {
			align-content: center;
		}
.flex--align-content-stretch {
			align-content: stretch;
		}
.flex--align-content-between {
			align-content: space-between;
		}
.flex--align-content-around {
			align-content: space-around;
		}
.flex--align-self-start {
			align-self: flex-start;
		}
.flex--align-self-end {
			align-self: flex-end;
		}
.flex--align-self-center {
			align-self: center;
		}
.flex--align-self-stretch {
			align-self: stretch;
		}
.flex--align-self-baseline {
			align-self: baseline;
		}
.flex--justify-self-start {
			justify-self: flex-start;
		}
.flex--justify-self-end {
			justify-self: flex-end;
		}
.flex--justify-self-center {
			justify-self: center;
		}
.flex--justify-self-stretch {
			justify-self: stretch;
		}
.flex--justify-self-baseline {
			justify-self: baseline;
		}
.flex--grow {
		flex-grow: 1;
	}
.flex--no-grow {
		flex-grow: 0;
	}
.flex--shrink {
		flex-shrink: 1;
	}
.flex--no-shrink {
		flex-shrink: 0;
	}
.flex--basis-50 {
			flex-basis: 50%;
		}
.flex--basis-min-text-width {
			flex-basis: 12.5rem;
		}
.auth {
	--input-width: auto;
	display: grid;
	grid-auto-rows: -webkit-min-content;
	grid-auto-rows: min-content;
	align-content: center;
	justify-content: center;
	place-content: center;
	height: 100%;
	max-width: 100%;
	width: calc(32rem + 1.5rem * 2);
	width: calc(32rem + var(--spacing) * 2);
	grid-auto-columns: 100%;
	margin-left: auto;
	margin-right: auto;
}
.auth__form {
		display: grid;
		grid-template-areas: 'fields' 'error' 'submit';
		grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content;
		grid-template-rows: min-content min-content min-content;
	}
.auth__fields {
		grid-area: fields;
	}
.auth__submit {
		grid-area: submit;
		align-self: end;
		justify-self: center;
		place-self: end center;
	}
.auth__error {
		grid-area: error;
	}
